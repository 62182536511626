// Color
$labelColor: #9ea0a5;
$primaryBgColor: #1665d8;
$primaryTextColor: #fff;
$secondartTextColor: #000;
$DropDownborderActive: #1d6de2;

// Size
$labelSize: 12px;
$primaryBtnSize: 14px;

// Button Settings
$primaryBtnBorder: 4px;
$emptyBorder: 1px solid transparent;

// Font Settings
$fontMedium: 500;
$fontSizeForMiniCard: 24px;