@mixin primaryButton($property) {
  // font-size: $primaryBtnSize;
  // border-radius: $primaryBtnBorder;
  background-color: rgba(59, 90, 152, 0.9);
  // color: $primaryTextColor;
  // font-weight: $fontMedium;
}

@mixin transparentButton($property) {
  // font-size: $primaryBtnSize;
  // border-radius: $primaryBtnBorder;
  // background-color: $emptyBorder;
  // color: $secondartTextColor;
  // font-weight: $fontMedium;
}

.primary-btn {
  @include primaryButton(none);

  border: none;
  width: auto;
  vertical-align: top !important;
  height: 38px;
  vertical-align: text-top;
  padding: 10px 15px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  &:hover,
  &:active,
  &:focus {
    // background-color: $primaryBgColor;
    color: white;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(
      to top,
      rgb(22, 101, 216),
      rgb(31, 111, 229)
    );
    opacity: 0.5;
  }
}

.transparent-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  vertical-align: top;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #000;
  }
}

.secondary-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #fff;
  vertical-align: text-top;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #000;
  }
}

.warning-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #ed6347;
  background-color: #ed6347;
  vertical-align: text-top;
  color: #fff;
  &:hover,
  &:active,
  &:focus {
    background-color: #ed6347;
  }
}

.activity-btn {
  background-color: #f2f2f5;
  color: #000;
  padding: 5px 15px;
  vertical-align: text-top;
  border-color: #e7e7e8;
  vertical-align: text-top;

  &:hover,
  &:active,
  &:focus {
    background-color: #f2f2f5;
    color: #000;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(
      to top,
      rgb(22, 101, 216),
      rgb(31, 111, 229)
    );
    opacity: 0.5;
  }
}

.close-btn {
  background-color: grey;
  color: #fff;
  padding: 8px 19px;
  border-radius: 6px;
  vertical-align: text-top;
  &:hover,
  &:active,
  &:focus {
    // background-color: $primaryBgColor;
    color: white;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(
      to top,
      rgb(22, 101, 216),
      rgb(31, 111, 229)
    );
    opacity: 0.5;
  }
}

.load-share-custom {
  .ant-select-selection--single {
    vertical-align: middle;
    min-height: 38px;
    padding-top: 3px;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    // border-color: $DropDownborderActive;
    box-shadow: none;
  }
  .ant-modal-body {
    line-height: 2;
  }
  .ant-modal-footer {
    padding: 18px 20px;
  }
}

.select-dropdown {
  .ant-select-dropdown-menu-item {
    border-bottom: 1px solid #eaeaea;
    padding: 8px 12px;
  }
}

.remit-modal {
  .ant-select {
    width: 100%;
  }
}

.btn-group-action {
  button {
    margin-left: 10px;
  }
}
