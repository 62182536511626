.payout-summary {
  margin: 16;
  min-height: 95vh;
  .ant-modal-body,
  .ant-card-body {
    padding: 12px
  }
}

.payoutDashBoard {
  background-color: #3b5a98;
  padding: 16px 30px;
  font-size: 20px;
  color: #fff;
  word-spacing: 3px;
  letter-spacing: 1px;
}

.payoutHeader {
  background-color: #fafafa;
  padding: 16px 30px;
  font-size: 20px;
  color: #000;
  word-spacing: 3px;
  letter-spacing: 1px;
}

.whiteCard {
  background: #f1f3f6;
  padding: 10px !important;
  margin: 10px 0px;
  border-radius: 5px;
}

.whiteCard-summary {
  background: #ffff;
  padding: 10px !important;
  margin: 10px 15px;
  border-radius: 5px;
}

.titlesName {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}

.titlesName-right {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  position: relative;
  text-align: right;
}

.titlesNumber {
  text-align: left;
}

.greyCard {
  background: #f7f7f7;
}

.greyCard2 {
  background: #ffffff;
  padding: 10px !important;
  margin: 10px 15px;
  overflow: scroll;
  height: 620px;
}

.buttonBn {
  text-align: center;
}

.divideCenter {
  margin: 5px 0 !important;
}

.divideCenter2 {
  margin: 8px -5px !important;
}

.redSet {
  color: #ff0202;
}

.padding_to_spinner {
  margin: 0 auto;
  width: 20px;
  margin-top: 120px;
}
.badge-payout {
  .ant-badge {
    width: 100% !important;
  }
}
