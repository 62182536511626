.outer-container-client-dashboard{
    float: right;
}
.search-client-dashboard{
    width: 20vw;
}

.reload-button-client-dashboard{
    margin-left: 10px;
    width: 3vw;
}
