.boxLittle {
  padding: 8px 12px;
  float: left;
  border: 1px solid #999;
  margin: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
}

.boxLittle:hover {
  padding: 8px 12px;
  float: left;
  border: 1px solid #999;
  margin: 4px 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  background: rgba(59, 90, 152, 0.9);
  border-color: rgba(59, 90, 152, 0.9);
  font-weight: 400;
  font-size: 14px;
}

.pad10 {
  padding: 3px;
}

.boxLittleSelected {
  padding: 8px 12px;
  float: left;
  border: 1px solid #999;
  margin: 4px 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  background: rgba(59, 90, 152, 0.9);
  border-color: rgba(59, 90, 152, 0.9);
  font-weight: 400;
  font-size: 14px;
}

.listPointer {
  cursor: pointer;
}

.boxusertitles {
  margin: 10px 10px;
  font-size: 14px;
  font-weight: 500;
}

.fleetModalFooter .ant-modal-content .ant-modal-footer {
  height: 86px !important;
  padding: 30px 25px;
  background: lightgrey !important;
}
.fleetModalFooter {
  top: 10px !important;
}

.fleetModalFooter .ant-modal-content .ant-modal-header {
  display: none !important;
}

.fleetModalFooter .ant-modal-content .ant-modal-header {
  display: none !important;
}

.userfooter {
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  padding: 10px;
  text-align: center;
}

.swaping {
  cursor: pointer;
  font-size: 30px;
  padding-top: 90px;
}

.cardTitle {
  border-bottom: 1px solid lightgray;
  margin-right: -24px;
  margin-left: -24px;
  padding: 0px 10px 10px 10px;
}

label.selectAll.ant-checkbox-wrapper {
  float: left;
  padding: 0px 29px 0px 35px;
}

.boxTitle {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}

.userBox {
  margin-top: 12px;
  height: 300px;
  overflow: scroll;
}

.capitalize {
  text-transform: capitalize;
}

.switchColor.ant-switch-checked {
  background-color: #24aa39 !important;
}

.success_cust:hover {
  background-color: #95de64 !important;
  border: 1px solid #95de64 !important;
  color: #fff !important;
}

.success_cust {
  background-color: #52c41a !important;
  border: 1px solid #52c41a !important;
  color: #fff !important;
}

label.topCheck.ant-checkbox-wrapper {
  margin-left: 4px;
  margin-bottom: 12px;
}

.svgAdj {
  margin-top: 3px;
  width: 14px;
  height: 14px;
}

.has-error {
  border-color: #f5222d;
}

.primaryValues {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clientValues {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moreValues {
  padding: 0px !important;
  color: rgba(59, 90, 152, 0.9);
}

.moreValues:hover {
  padding: 0px !important;
  text-decoration: underline;
}
.leftPanel {
  float: left;
  width: 50%;
  overflow: hidden;
}

.rightPanel {
  float: right;
}

.listHeader {
  margin-bottom: 24px;
}

.legendsBox {
  background: #fff;
  z-index: 999;
  position: absolute !important;
  padding: 18px 18px;
  width: 87%;
  border: 1px solid #999;
  margin-top: 10px;
}
.mainBox {
  height: 373px;
  overflow: scroll;
}

.MainTitle {
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0px;
}

.SubTitles {
  font-size: 14px;
  padding: 6px 0px;
  text-decoration: underline;
}

.clearFix {
  clear: both;
}

.boxhider {
  padding: 6px 18px 3px 14px;
  border: 1px solid #999;
  position: absolute !important;
  z-index: 1000;
  background: #fff;
  font-size: 16px;
  margin-top: 24px;
  cursor: pointer;
  transition: all 0.5s;
  transform: translate3d(0, 0, 0);
}

.buttonBox {
  position: absolute !important;
  z-index: 1000;
  bottom: 12px;
  left: 20px;
}

.switchColorpink.ant-switch-checked {
  background-color: #e61859 !important;
}

.buttonColor {
  background-color: #e61859 !important;
  border: #e61859 !important;
  color: #fff;
}

.filteredView {
  position: absolute !important;
  z-index: 1000;
  bottom: 350px;
  left: 0px;
}

.filteredView2 {
  position: absolute !important;
  z-index: 1000;
  bottom: 350px;
  left: 0px;
}

.listindexLevel {
  z-index: 999;
  position: absolute !important;
  background: #fff;
  max-height: 300px;
  overflow: scroll;
  width: 100%;
}

.listindexLevel2 {
  z-index: 999;
  position: absolute !important;
  background: #fff;
  max-height: 300px;
  overflow: scroll;
  width: 100%;
}

// .listDetails{
//     cursor: pointer;
// }

// .listDetails:hover{
//     cursor: pointer;
//     text-decoration: underline;
// }

.leaflet-top {
  z-index: 800 !important;
}

// .mapControls .leaflet-right .leaflet-control{
//     display: none !important;
// }

.leaflet-control-layers.leaflet-control {
  display: none !important;
}

.hyperlocalModalFooter {
  top: 10px !important;
}

.listindexLevel .ant-list-header {
  position: sticky !important;
  top: 0 !important;
  background: #fff !important;
  z-index: 1001 !important;
}

.addCheck {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}

.boxNewDis {
  background-color: #f1f1f1 !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}
.listData {
  width: 300px;
}

.fitbox {
  margin-left: 2px !important;
}

// .mapControlsbox a.leaflet-control-zoom-fullscreen.fullscreen-icon {
//     background: #fff url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png) no-repeat 0 0;
//     background-size: 26px 52px;
// }

// .mapControlsbox .leaflet-fullscreen-on a.leaflet-control-zoom-fullscreen.fullscreen-icon {
//     background: #fff url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png) no-repeat 0 0;
//     background-size: 26px 52px;
// }

.mapControlsbox .fullscreen-icon {
  background: #fff
    url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png)
    no-repeat 0 0;
  background-size: 26px 52px;
}
.mapControlsbox .leaflet-retina .fullscreen-icon {
  background: #fff
    url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png)
    no-repeat 0 0;
  background-size: 26px 26px;
}

.order-list .ant-list-item {
  padding: 0px !important;
}

.transfer-list-item {
  ul.ant-transfer-list-content > div {
    margin-bottom: 15px;

    .list-phone {
      padding-left: 28px;
    }
  }
}

.order-active {
  .ant-tabs-tab-active.ant-tabs-tab {
    border-left: 4px solid #091c9b !important;
  }
}

.order-undel-list {
  padding: 10px;
  border-radius: 4px;
}

.order-Undel-msg {
  background-color: #fbda81;
  border: 2px solid #f7b500;
  height: 60px;
  padding: 10px;
  margin-bottom: 10px;
}
