.outer-div-fmlm {
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
}

.switchColor.ant-switch-checked {
  background-color: #24aa39 !important;
}

.inputModal-number-format {
  width: 30%;
  border-radius: 4px;
  border: solid 1px #e6e7e7;
  background: #fff;
}

.upper-inputModal-fmlm {
  background-color: #dee6ec;
  border: 1.5px solid #b9c4cf;
  height: 14vh;
  padding: 20px;
}

.lower-inputModal-fmlm {
  background-color: #f8f9fa;
  border: 1.5px solid #c2cfd9;
  height: 14vh;
  margin-top: 20px;
  padding: 20px;
}

.input-div-inputModal-fmlm {
  display: flex;
}

.input-inputModal-fmlm {
  width: 10wv;
}

.submit-inputModal-fmlm {
  margin-top: 5%;
  justify-content: center;
  background-color: #2d5de5;
  color: white;
}

.lower-div-fmlm {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

// City congfig
