.pincode-client-onboarding{
    width: 29vw;
    height: 36px;
    padding: 0px 0px 0px 12px;
    border-radius: 4px;
    border: solid 1px #d9d9d9;
    display: 'inline-block'    
  }

.pincode-client-onboarding:focus{
    outline: solid 1px #51aefb;
}
.pincode-client-onboarding::placeholder{
    color: #bfbfbf;
}
