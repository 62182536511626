.header {
  background-color: #fafafa;
  padding: 16px 30px;
  font-size: 20px;
  color: #000;
  word-spacing: 3px;
  letter-spacing: 1px;
}
.rate-card-container {
  background-color: #f3f4f8;
  width: 100%;
  min-height: 100vh;
  padding: 8px;


  .card {
    background-color: #b8d0e4;
    padding: 8px;
    border-radius: 8px;
    color: #0a0808;
    margin-bottom: 10px;

    .card-conditions {
      background-color: #f1f6fb;
      margin-top: 8px;
      padding: 8px 16px;

      p {
        margin: 0;
        font-size: 10px;
      }
      p:first-child {
        font-size: 12px;
        font-weight: bold;
      }
    }
    .list-view-card {
      background-color: #f1f6fb;
      margin-top: 16px;
      margin-bottom: 0px;
      padding: 8px;

      .title {
        font-size: 12px;
        font-weight: 600;
      }
      .description {
        font-size: 10px;
      }
    }
    .combo-list-view-card {
      background-color: #f1f6fb;
      margin-top: 8px;
      padding: 8px;
      border: 1px solid #dedede;

      .title {
        font-size: 14px;
        font-weight: 600;
      }
      .description {
        font-size: 10px;
      }
    }

    .card-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    img.icon {
      width: 24px;
      height: 24px;
    }

    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .description {
      font-size: 10px;
    }
  }

  // timeline css
  .timeline {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    padding: 0;
  }

  .li {
    transition: all 200ms ease-in;
    width: 70px;
  }

  .li:first-child {
    width: 100%;
    .status,
    .timestamp {
      h4 {
        width: 100px
      }
    }
  }

  .timestamp {
    margin-bottom: 5px;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 100;
    font-size: 12px;
  }

  .status {
    padding: 0px 3px;
    display: flex;
    justify-content: flex-start;
    border-top: 2px solid #2b2b2b;
    position: relative;
    transition: all 200ms ease-in;
  }
  .timestamp h4,
  .status h4 {
    font-size: 11px;
    font-weight: normal;
    margin-top: 10px;
  }
  .status:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 25px;
    position: absolute;
    top: -7px;
    // left: 42%;
    transition: all 200ms ease-in;
  }

  .li.complete .timestamp {
    align-items: center;
  }
  .li.complete .status {
    border-top: 2px solid #2b2b2b;
    justify-content: center;
  }
  .li.complete .status:before {
    background-color: #fd6a00;
    border: none;
    transition: all 200ms ease-in;
  }
}
