body{
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.outermost-div-otp{
    display:flex;
}

.outer-left-div-otp{
    width: 720px;
    height: 760px;
    padding: 220px 160px 258px;
    background-color: #fff;
}
.logo-valid-otp{
    width: 720px;
    height: 760px;
    padding: 170px 160px 258px;
    /* background-color: red; */
} 

.logo-otp{
    width: 278px;
    height: 80px;
    flex-grow: 0;
    margin: 0 122px 10px 0;
    object-fit: contain;
}


#title-opt{
    width: 120px;
    height: 30px;
    flex-grow: 0;
    margin: 30px 281px 0px 0;
    font-family: Roboto;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: left;
    color: #051c57;

}

.login-tabpane-otp{
    width: 400px;
    height: 36px;
    margin: 32px 0 10px;
    padding: 8px 224px 8px 12px;
    border-radius: 4px;
    border: solid 1px #e6e7e7;
    background-color: #fff;
}

.login-tabpane-otp::placeholder{
    /* design - width: 164px; */
    width: 170px; 
    height: 20px;
    opacity: 0.4;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #273142;
}

.login-tabpane-otp-received{
    width: 400px;
    height: 36px;
    margin: 0px 0 10px;
    padding: 8px 224px 8px 12px;
    border-radius: 4px;
    border: solid 1px #e6e7e7;
    background-color: #fff;
}
.login-tabpane-otp-received::placeholder{
    /* design - width: 164px; */
    width: 170px; 
    height: 20px;
    opacity: 0.4;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #273142;
}

.confirm-otp{
    width: 45px;
    height: 22px;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.outer-right-div{
    width: 50%;
    background-color: #3B56EA;
    background-image: url('../../assets/img/loginimg.svg');
    background-repeat:no-repeat;
    height: 100vh;

}

@media (min-width: 640px) { 
    body {font-size:1rem;}     
}
@media (min-width:960px) { 
    body {font-size:1.2rem;} 
}
@media (min-width:1024px) { 
    body {font-size:1.2rem;
        
}
@media (min-width:1100px) { 
    body {font-size:1.5rem;} 
}   }
