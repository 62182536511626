@import "../../../assets/styles/common.scss";

.pincode-select-wrapper{
    width: 576px;

    & .ant-card-head-title{
        @include cardMiniHeader(none);
    }
    & div:first-child{
        label{
            padding-top: 0
        }
    }

    label{
        display: block;
        padding: 16px 0 12px;
        color: $labelColor;
        font-size: $labelSize;
    }
}

.pincode-select-search{
    display: none;
    &.valid-input{
        display: block;
    }
}

.pincode-select-search-invalid{
    display: none;
    &.invalid-input{
        display: block;
    }
}

.pincode-select{
    width: 80%
}

.action-items{
    margin: 30px -24px 0;
    padding: 25px 24px 0;
    border-top: 1px solid #eaeaea;
}