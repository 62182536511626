@import "./variables.scss";
@import "./components.scss";

.float-right {
  float: right;
}

.clearfix {
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.load-share-custom {
  .ant-modal-header {
    padding: 24px 24px;
    font-size: 20px;
  }
}

.text-blue {
  color: $DropDownborderActive;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.ant-table-tbody {
  .ant-table-row:nth-child(even) {
    background-color: #f9f9f9;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.inline-block {
  display: inline-block;
}

button.ant-btn.filter-btn.filter-active {
  border: 1px solid #40a9ff;
}

.sendemail {
  width: 140px;
  margin-right: 10px;
  padding: 18px 10px;
}

.width-half {
  width: 50%;
}

.status-approve-reject {
  background-color: #eaeaea;
  text-align: center;
  border-radius: 5px;
  color: #000;
  padding: 3px 3px;
}
.status-approve-reject.REJECTED {
  color: #e6492d;
}
