@charset "UTF-8";
.elChildren {
  display: none;
  font-weight: normal;
  border-left: 1px dotted #ddd; }

.toggleObject:before {
  content: "▸";
  color: #333;
  line-height: 1em;
  margin-right: 5px; }

.expand > .toggleObject:before {
  content: "▾"; }

.Objelement.expand > .elChildren {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* grid-gap: 2em; */
  width: 40rem;
  /* text-align: center;*/ }

.toggleObject,
.stringSchema,
.numberSchema {
  cursor: pointer; }

.array-schema {
  color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-style: italic;
  margin: 0px 6px 0px 0px;
  cursor: default; }

.spin-config {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
