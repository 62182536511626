.count-div-staffStatus {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaedf2;
  padding-bottom: 20px;
}

.total-count-div {
  background: #f6f7f9;
  border: 1px solid #ebedf8;
  width: 348px;
  border-radius: 4px;
  height: 88px;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.total-count-div:hover {
  background: #e8effd;
  cursor: pointer;
}

.total-count-div-selected {
  color: white;
  background-color: #42409b;
  border: 1px solid rgba(77, 74, 181, 0.24);
  width: 348px;
  border-radius: 4px;
  height: 88px;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
}

.total-count-value {
  font-size: 18px;
  font-weight: 800;
}
.input-group-staffStatus {
  width: 30%;
  margin: 10px 0 10px 0;
  padding-top: 10px;
}

.input-staffStatus .ant-input {
  border: 1px solid #d4d4ed;
  width: 100%;
  border-radius: 8px;
}

.input-button-staffStatus
  .ant-input-search-enter-button
  + .ant-input-group-addon
  .ant-input-search-button,
.ant-input-search-enter-button
  input
  + .ant-input-group-addon
  .ant-input-search-button {
  border-radius: 0 8px 8px 0;
}

.input-filter-grp-staffStatus {
  display: flex;
  margin: 8px 0 8px 0;
}

.filter-outer-div-staffStatus {
  display: flex;
  margin: 22px 0px 20px 0px;
}

.filter-inner-div-staffStatus {
  display: flex;
}

.filter-btn-div-staffStatus {
  margin-left: 50px;
  font-size: 14px;
  background: #f6f7fb;
  border-radius: 24px;
  height: 5vh;
}

.filter-btn-staffStatus {
  color: #525050;
  background: #ffffff;
  border: 1px solid rgba(77, 74, 181, 0.24);
  border-radius: 16px;
  margin: 3px 5px 3px 3px;
}

.filter-btn-staffStatus-selected {
  color: white;
  background-color: #42409b;
  border: 1px solid rgba(77, 74, 181, 0.24);
  border-radius: 16px;
  margin: 3px 5px 3px 3px;
}

.cancelBtn-staffStatus-selected {
  color: white;
  margin-left: 5px;
  background: none;
  border: none;
  height: 20px;
  width: 4px;
  display: inline;
}

.cancelBtn-staffStatus-selected:hover {
  background: none;
  color: red;
  cursor: pointer;
}

.display-none-staffStatus-selected {
  display: none;
}

.table-staffStatus {
  background: transparent;
  border: none;
}

.FEStatusDashboard {
  .ant-table-thead > tr > th {
    background-color: transparent;
    border: none;
    padding-bottom: 0px;
    color: #808080;
    background: #f6f7fb;
    padding: 0 0 0 20px;
  }
  .ant-table-bordered .ant-table-body > table {
    border: 1px solid #ebedf8;
    border-radius: 4px;
  }
  .ant-table-bordered .ant-table-tbody > tr > td {
    border: none;
  }
  .select-container.ant-form-item-control-wrapper {
    width: 50%;
  }
  .anticon-search {
    font-size: 20px;
    padding-top: 5px;
  }
}

.modal-staffStatus .ant-modal-content {
  border-radius: 24px;
  width: 468px;
  height: 317px;
  border-radius: 24px;
}

.modal-staffStatus .ant-modal-header {
  border-radius: 24px 24px 0 0;
  border: none;
  font-weight: 700;
  font-size: 24px;
}
.modal-staffStatus .ant-modal-footer {
  margin-top: 100px;
}
.modal-staffStatus .ant-btn {
  height: 36px;
  width: 130px;
  border-radius: 8px;
  margin-top: 10px;
}

.option-staffStatus
  // .ant-select-dropdown-menu-item
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e8effd;
}
.option-staffStatus .ant-select-dropdown-menu-item {
  line-height: 40px;
  border-bottom: rgba(255, 255, 255, 0.2);
}

.deliveryHistory-div-staffStatus {
  display: flex;
  border: 1px solid black;
  border-radius: 4px;
}
