@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/leaflet.css);
.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 5px 0px 5px 10px !important; }
  .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
    left: initial !important;
    right: 16px !important; }

.error-notification {
  background-image: linear-gradient(to bottom, #e73c1e, #cf3014) !important;
  color: white !important; }
  .error-notification .ant-notification-notice-message {
    color: white !important; }

.success-notification {
  background-image: linear-gradient(to top, #34aa44, #38b249) !important;
  color: white !important; }
  .success-notification .ant-notification-notice-message {
    color: white !important; }

.tabspane-custom .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  font-weight: normal;
  min-height: 58px; }

.tabspane-custom .ant-tabs-nav .ant-tabs-tab-active {
  color: #1665d8; }

.errorLog {
  color: red;
  text-align: center;
  padding: 12px 0px 0px 0px;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-weight: 700;
  font-size: 16px; }

.blinking {
  -webkit-animation: blinkingText 5s infinite;
          animation: blinkingText 5s infinite; }

@-webkit-keyframes blinkingText {
  0% {
    color: transparent; }
  49% {
    color: red; }
  50% {
    color: red; }
  99% {
    color: red; }
  100% {
    color: transparent; } }

@keyframes blinkingText {
  0% {
    color: transparent; }
  49% {
    color: red; }
  50% {
    color: red; }
  99% {
    color: red; }
  100% {
    color: transparent; } }

.sidebar-custom {
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
  z-index: 99;
  min-height: 100%; }
  .sidebar-custom .anticon svg {
    color: #3B5A98; }
  .sidebar-custom .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #0000;
    font-size: 15px;
    font-weight: 600; }
    .sidebar-custom .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span {
      color: #000; }
  .sidebar-custom .ant-menu-item .anticon + span,
  .sidebar-custom .ant-menu-submenu-title .anticon + span {
    color: #0000008a; }
  .sidebar-custom .ant-menu-item .anticon {
    font-size: 20px; }
  .sidebar-custom .ant-menu-item > a:hover {
    color: black; }
  .sidebar-custom .ant-menu-item-active {
    font-weight: 600;
    color: black; }
  .sidebar-custom .ant-menu-inline {
    padding-top: 12px; }
  .sidebar-custom .menu-item-label {
    font-size: 15px; }
  .sidebar-custom .menu-item-img {
    padding: 4px;
    margin-right: 15px;
    color: #fff;
    border-radius: 4px;
    width: 35px; }
  .sidebar-custom .menu-item-container {
    display: flex;
    height: 36px !important;
    line-height: 36px !important; }
  .sidebar-custom .menu-item-container-active {
    border-right: 5px solid #3B5A98; }
    .sidebar-custom .menu-item-container-active img {
      background-color: #3B5A98; }
    .sidebar-custom .menu-item-container-active a {
      font-weight: 600; }

.trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s; }

.Header {
  display: flex;
  min-height: 70px;
  background-color: #3b5a98;
  justify-content: space-between; }
  .Header .logo-img {
    width: 12em;
    height: 48%;
    vertical-align: middle;
    margin: 1.2em .5em; }
  .Header .lhs {
    object-fit: fill; }
  .Header .rhs {
    display: flex; }
    .Header .rhs .waybill-track {
      display: inherit;
      width: auto;
      min-width: 140px;
      margin-right: 12px;
      margin-top: 20px;
      height: 32px; }
  .Header .waybill-track {
    margin: 7px 10px;
    height: 30px; }
    .Header .waybill-track input {
      border-radius: 5px 0px 0px 5px;
      padding: 9px;
      height: 32px;
      margin-right: 0px;
      border-width: 0; }
    .Header .waybill-track button {
      border-radius: 0px 5px 5px 0px;
      border-width: 0;
      border-left: 1px solid;
      cursor: pointer; }
  .Header .img-size {
    width: 17em;
    height: 70px; }
  .Header .hub-modal .ant-modal .ant-modal-content .ant-modal-header {
    background: #3b5a98 !important;
    color: #fff !important; }


.ant-btn-primary {
  background-color: rgba(59, 90, 152, 0.9) !important;
  border-color: rgba(59, 90, 152, 0.9) !important; }

.ant-btn-success {
  background-color: #95de64 !important;
  border-color: #73d13d !important; }

.ant-btn-info {
  background-color: #fff566 !important;
  border-color: #ffec3d !important; }

.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 5px 0px 5px 10px !important; }
  .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
    left: initial !important;
    right: 16px !important; }


.app-popup {
  width: 445px;
  height: 400px;
  border-radius: 4px; }
  .app-popup .input-label {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #9ea0a5; }
  .app-popup .btn-danger {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
    background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
    color: white;
    margin-right: 10px; }
  .app-popup .btn {
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    margin-right: 10px; }
  .app-popup .btn-success {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
    background-image: linear-gradient(to top, #34aa44, #38b249);
    color: white;
    margin-right: 10px; }
  .app-popup .btn:hover {
    color: #000000; }
  .app-popup .btn-danger:hover {
    box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
    background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
    color: white; }
  .app-popup .btn-success:hover {
    box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
    background-image: linear-gradient(to top, #34aa44, #38b249);
    color: white; }

.TrackingFooter {
  display: flex;
  justify-content: space-between;
  height: 50px; }
  .TrackingFooter .footer-left {
    display: flex;
    width: 30%; }
    .TrackingFooter .footer-left .contact-container {
      display: flex;
      flex-direction: column;
      margin: 0px 12px; }
      .TrackingFooter .footer-left .contact-container .contact-number {
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #3e3f42;
        margin-bottom: 5px; }
      .TrackingFooter .footer-left .contact-container .contact-name {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #9ea0a5;
        margin-bottom: 5px; }
    .TrackingFooter .footer-left .phone-icon {
      font-size: 27px;
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
      margin-top: 17px; }
  .TrackingFooter .footer-right {
    padding-top: 12px; }
    .TrackingFooter .footer-right .btn-danger {
      border-radius: 4px;
      box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
      background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
      color: white;
      margin-right: 10px; }
    .TrackingFooter .footer-right .btn-success {
      border-radius: 4px;
      box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
      background-image: linear-gradient(to top, #34aa44, #38b249);
      color: white;
      margin-right: 10px; }
  .TrackingFooter .del-undel-form {
    position: absolute;
    right: 0;
    bottom: 0px; }


.TrackingPopup {
  background-color: #fff; }
  .TrackingPopup .header {
    width: 100%;
    height: 65px;
    border-bottom: 1px solid lightgray;
    padding: 15px;
    font-family: Lato;
    font-size: 24px;
    font-weight: bold; }
    .TrackingPopup .header .copy-icon {
      color: lightgray;
      margin: 0 10px;
      cursor: pointer; }
  .TrackingPopup .sidebar {
    width: 30%;
    border-right: 1px solid lightgray;
    padding: 15px; }
    .TrackingPopup .sidebar .ant-collapse-header {
      font-weight: 500 !important;
      font-style: normal !important;
      font-stretch: normal !important;
      line-height: 1.5 !important;
      letter-spacing: normal !important;
      color: #3b5a98 !important; }
    .TrackingPopup .sidebar .pod-image {
      width: 50px;
      height: 50px;
      margin: 2px;
      border: 1px solid;
      border-radius: 5px;
      color: #007bff; }
    .TrackingPopup .sidebar .row {
      display: flex;
      justify-content: space-between; }
    .TrackingPopup .sidebar .bold {
      color: #9ea0a5;
      width: 45%; }
    .TrackingPopup .sidebar .data {
      width: 55%;
      color: #000000; }
  .TrackingPopup .content {
    display: flex; }
  .TrackingPopup .main-content {
    padding: 15px;
    max-height: 430px;
    overflow-y: auto; }
    .TrackingPopup .main-content .content-header {
      font-family: 'Lato' !important;
      font-size: 16px !important;
      font-weight: bold !important;
      font-style: normal !important;
      font-stretch: normal !important;
      line-height: 1.5 !important;
      letter-spacing: normal !important;
      color: #3e3f42 !important; }
    .TrackingPopup .main-content .expanded-row .left-col {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 150px; }
    .TrackingPopup .main-content .expanded-row .expanded-data-row {
      margin-bottom: 10px; }
      .TrackingPopup .main-content .expanded-row .expanded-data-row p {
        margin: 0; }
      .TrackingPopup .main-content .expanded-row .expanded-data-row .heading {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #9ea0a5; }
      .TrackingPopup .main-content .expanded-row .expanded-data-row .data {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #3e3f42; }
      .TrackingPopup .main-content .expanded-row .expanded-data-row .init-caps {
        text-transform: capitalize; }
      .TrackingPopup .main-content .expanded-row .expanded-data-row .flex {
        display: flex;
        width: 230px;
        justify-content: space-between;
        flex-wrap: wrap; }
        .TrackingPopup .main-content .expanded-row .expanded-data-row .flex .inline {
          display: inline;
          margin-right: 5px; }
    .TrackingPopup .main-content .icon-warning {
      font-size: 19px;
      color: #ff6d00; }

.expand-link {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #3b5a98; }

.expand-link:hover {
  color: #3b5a98; }

.greyed-out {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9ea0a5; }

.clickable-link {
  color: #3a9a2b8a;
  cursor: pointer; }

body * {
  font-family: "Roboto", sans-serif; }

.Header {
  display: flex;
  min-height: 70px;
  background-color: #3b5a98;
  justify-content: space-between; }
  .Header .logo-img {
    width: 12em;
    height: 48%;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: 20px; }
  .Header .rhs {
    display: flex; }

.App {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif; }
  .App .icon {
    line-height: 64px;
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    top: 7px;
    position: relative;
    font-size: 22px;
    padding: 5px 9px; }
  .App .padding-container {
    padding: 0 1.8rem;
    height: 85vh; }
  .App .tabs-container {
    min-height: 80px;
    max-height: 80px; }
    .App .tabs-container .ant-tabs-tab {
      text-align: center;
      color: rgba(0, 0, 0, 0.54);
      letter-spacing: 0;
      font-weight: bold;
      border-bottom: unset; }
    .App .tabs-container .ant-tabs-ink-bar {
      background-color: #3b5a98;
      height: 4px; }
    .App .tabs-container .ant-tabs-nav .ant-tabs-tab:hover {
      color: #3b5a98; }
  .App .btn-danger {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
    background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
    color: white;
    margin-right: 10px; }
  .App .btn {
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    margin-right: 10px; }

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 15px 16px;
  font-weight: normal; }

tr.ant-table-expanded-row td,
tr.ant-table-expanded-row:hover td {
  background: #ebeff2; }

.childTable tbody.ant-table-thead > tr > th {
  background: #f8f9fa; }

.childTable tbody.ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #fff; }

.childTable tbody.ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgba(246, 246, 248, 0.7); }

.right {
  float: right; }

.header-select-drop.ant-select.ant-select {
  display: inline-block;
  width: auto;
  min-width: 140px;
  margin-right: 12px; }

.name-splitter {
  margin: 0 5px; }

.light-text {
  color: gray !important; }

.bold {
  font-weight: 600 !important; }

.form-icon {
  width: 20px;
  height: 20px;
  font-size: 17px;
  margin-top: 10px;
  cursor: pointer; }

.blue {
  color: #0336ff; }

.flex {
  display: flex; }

.justify-space-around {
  justify-content: space-around; }

.flex-column {
  flex-direction: column; }

.width-50 {
  width: 50%; }

.width-45 {
  width: 45%; }

.Card {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  align-self: center; }

.small-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #97a0af; }

.bold-small-text {
  font-weight: bold;
  line-height: 1.57; }

.margin-left-10 {
  margin-left: 10px; }

.margin-right-10 {
  margin-right: 20px; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left; }

.background-default {
  background-color: #e0e0e0;
  color: #fff; }

.background-primary {
  background-color: #4680ff;
  color: #fff; }

.background-success {
  background-color: #93BE52;
  color: #fff; }

.background-info {
  background-color: #62d1f3;
  color: #fff; }

.background-warning {
  background-color: #FFB64D;
  color: #fff; }

.background-danger {
  background-color: #FC6180;
  color: #fff; }

.Audit-Notice {
  z-index: 1;
  background-color: #D67575;
  padding: 10px 15px;
  color: #fff;
  padding-left: 35px;
  min-height: 56px; }
  .Audit-Notice p {
    padding-top: 6px;
    margin: 0;
    display: inline-block;
    font-size: 16px; }
  .Audit-Notice .logo-img {
    width: 14em;
    height: 50%;
    vertical-align: middle;
    margin-left: 6px; }

.recheck {
  float: right; }

.ant-modal-wrap.audit-modal {
  top: -62px; }

.audit-modal .ant-modal-body {
  padding: 0; }
  .audit-modal .ant-modal-body .header {
    background-color: #3FC380;
    padding: 15px;
    border-bottom: 1px solid #e9ecef;
    color: #fff;
    font-size: 150%;
    border-radius: 3px; }
  .audit-modal .ant-modal-body p {
    font-size: 14px;
    color: #000;
    padding: 20px 14px;
    margin-bottom: 0; }
  .audit-modal .ant-modal-body .footer {
    text-align: right;
    padding: 0 20px 20px 0; }

.audit-modal .btn {
  background-color: #3B5A98;
  border-color: #3B5A98;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  font-size: 16px;
  padding: 0px 20px; }

.clickHere {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer; }

.audit-status {
  background-color: #3B5A98;
  border-color: #3B5A98;
  color: #fff;
  border-radius: 2px;
  padding: 0px 10px; }

.Tracking {
  display: flex;
  flex-direction: column; }
  .Tracking .Card {
    width: 100%; }
  .Tracking .gutter {
    margin: 10px; }
  .Tracking .smallMessage {
    width: 95%;
    margin: 0; }
  .Tracking .tracking-primary-btn {
    border-radius: 4px;
    background-color: rgba(59, 90, 152, 0.9);
    color: white; }
    .Tracking .tracking-primary-btn:hover, .Tracking .tracking-primary-btn:active, .Tracking .tracking-primary-btn:focus {
      background-color: rgba(59, 90, 152, 0.9) !important;
      color: white !important; }
    .Tracking .tracking-primary-btn .track-icon {
      padding: 0 5px;
      width: 30px;
      height: 25px; }
  .Tracking .waybills-input {
    width: 75%;
    margin-right: 10px;
    max-height: 100px !important;
    overflow-y: auto; }

.Steps .ant-steps {
  width: 70%;
  margin: 0 auto; }

.Steps .ant-steps-dot .ant-steps-item-tail {
  width: 115% !important;
  top: 15px !important;
  margin: 0 0px 0px 72px !important; }

.Steps .ant-steps-dot .ant-steps-item-icon {
  width: 15px !important;
  height: 15px !important;
  line-height: 15px !important; }

.TrackingDetails .tracking-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; }

.TrackingDetails .Card {
  margin-top: 30px; }

.TrackingDetails .border-top-light {
  border-top: 1px solid lightgray; }

.TrackingDetails .padding-24-top-bottom {
  padding: 24px 0; }

.TrackingDetails .search-bar input {
  width: 220px; }

.TrackingDetails .search-bar.ant-input-affix-wrapper .ant-input-suffix {
  right: 0px; }

.TrackingDetails .download-btn {
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: rgba(98, 2, 238, 0);
  margin-right: 10px;
  color: #3b5a98; }

.TrackingDetails .large-col {
  max-width: 200px !important;
  overflow-wrap: break-word;
  white-space: normal; }
  .TrackingDetails .large-col p {
    overflow-wrap: break-word;
    white-space: normal; }

.TrackingDetails .small-col {
  max-width: 100px !important;
  overflow-wrap: break-word;
  white-space: normal; }
  .TrackingDetails .small-col p {
    overflow-wrap: break-word;
    white-space: normal; }

.TrackingDetails .small-text {
  font-size: 12px;
  color: darkgray; }

.vehicleTitlebox {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  padding: 0px 0px 20px 0px; }

.bluecircle2 {
  width: 20px;
  height: 20px;
  font-family: Roboto;
  font-size: 10.3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  border-radius: 50%;
  line-height: 2;
  margin: 12px 120px;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #091c9b; }

.pb_20 {
  padding-bottom: 20px; }

.vehicle-card {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff; }

.divider {
  height: 1px;
  background-color: #eaedf3;
  clear: both; }

.vehicle_list {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  padding: 12px 24px;
  float: left; }

.vehicle_box {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  padding: 12px 24px 0px 24px;
  position: relative;
  cursor: pointer;
  border-left: 3px solid #fff; }

.vehicle_box:hover {
  background-color: #f6f9fd;
  border-left: 3px solid #4388ed; }

.vehicleNumber {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  float: left; }

.vehicleAddr1 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  float: left;
  padding: 0px 26px 10px 26px; }

.vehicleDate {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #9ea0a5;
  padding: 2px 0px; }

.statusRunning {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #1665d8;
  padding: 2px 0px; }

.statusStopped {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #e6492d;
  padding: 2px 0px; }

.vehicleTag {
  border-radius: 4px;
  background-color: #ebf3ff;
  font-size: 10px;
  padding: 2px 5px;
  float: right; }

.pt_10 {
  padding-top: 10px; }

.vehiclescroll {
  height: 500px;
  overflow: scroll; }

.noData {
  text-align: center;
  font-size: 20px;
  padding: 50px 0px; }

.statusRunning {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #1665d8;
  padding: 2px 0px;
  float: right; }

.statusStopped {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #e6492d;
  padding: 2px 0px;
  float: right; }

/* @import url('https://api.mapbox.com/mapbox-gl-js/v1.4.1/mapbox-gl.css'); */

.leaflet-container {
	height: 500px;
	width: 100%;
}
.mapContainer {
	position: relative;
	width: 100%;
	height: 500px;
}

.boxapprox{
	width: 100%;
	height: 500px;
}

.mapContainer .item {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 31px -32px 70px 10px rgba(0, 0, 0, 0.55);
	position: relative;
	overflow: hidden;
	height: calc(100% - 16px) !important;
}

.mapContainer .textItemContainer {
	position: relative !important;
}

.mapContainer .textItemWrapper {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.flyoverContainer::before {
	content: ' ';
	position: relative;
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 100px 50px 0 0;
	border-color: transparent #fff transparent transparent;
	-webkit-transform: rotate(210deg);
	        transform: rotate(210deg);
	opacity: 0.7;
	top: 385px;
	left: -20px;
}

.markerClusterSmall {
	border-radius: 20px;
	font-size: 0.6vw;
	color: #fff;
	font-weight: 900;
	font-family: Open Sans;
	text-transform: uppercase;
}

.markerClusterSmallDivOne {
	position: absolute;
	margin-left: -5px;
	margin-top: -5px;
	border-radius: 7.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 3s; */
}

.markerClusterSmallDivTwo {
	position: absolute;
	width: 5em;
	height: 5em;
	border-radius: 6em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 2.5s; */
}

.markerClusterSmallDivThree {
	width: 3.5em;
	height: 3.5em;
	border-radius: 4.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 2s; */
}

.markerClusterSmallDivFour {
	width: 2em;
	height: 2em;
	border-radius: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 1.5s; */
}

.markerClusterSmallLabel {
	display: flex;
	justify-content: center;
	align-items: center;
}

.markerClusterMedium {
	border-radius: 40px;
	font-size: 0.6vw;
	color: #fff;
	font-weight: 900;
	font-family: Open Sans;
	text-transform: uppercase;
}

.markerClusterMediumDivOne {
	position: absolute;
	margin-left: -5px;
	margin-top: -5px;
	border-radius: 9.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 3s; */
}

.markerClusterMediumDivTwo {
	position: absolute;
	width: 6.5em;
	height: 6.5em;
	border-radius: 8em;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-animation: fadein 2.5s;
	        animation: fadein 2.5s;
}

.markerClusterMediumDivThree {
	width: 5em;
	height: 5em;
	border-radius: 6.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 2s; */
}

.markerClusterMediumDivFour {
	width: 3.5em;
	height: 3.5em;
	border-radius: 5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 1.5s; */
}

.markerClusterMediumLabel {
	display: flex;
	justify-content: center;
	align-items: center;
}

.markerClusterLarge {
	border-radius: 70px;
	font-size: 0.6vw;
	color: #fff;
	font-weight: 900;
	font-family: Open Sans;
	text-transform: uppercase;
}

.markerClusterLargeDivOne {
	position: absolute;
	margin-left: -5px;
	margin-top: -5px;
	border-radius: 12em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 3s; */
}

.markerClusterLargeDivTwo {
	position: absolute;
	width: 9em;
	height: 9em;
	border-radius: 10.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 2.5s; */
}

.markerClusterLargeDivThree {
	width: 7.5em;
	height: 7.5em;
	border-radius: 9em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 2s; */
}

.markerClusterLargeDivFour {
	padding: 1px;
	width: 6em;
	height: 6em;
	border-radius: 7.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 1.5s; */
}

.markerClusterLargeLabel {
	display: flex;
	justify-content: center;
	align-items: center;
}
.markerClusterLabel {
	display: flex;
	justify-content: center;
	align-items: center;
 }

.markerClusterLargeXL {
	border-radius: 70px;
	font-size: 0.6vw;
	color: #fff;
	font-weight: 900;
	font-family: Open Sans;
	text-transform: uppercase;
}

.markerClusterLargeXLDivOne {
	position: absolute;
	margin-left: -5px;
	margin-top: -5px;
	border-radius: 12em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 3s; */
}

.markerClusterLargeXLDivTwo {
	position: absolute;
	width: 10.5em;
	height: 10.5em;
	border-radius: 10.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 2.5s; */
}

.markerClusterLargeXLDivThree {
	width: 9em;
	height: 9em;
	border-radius: 9em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 2s; */
}

.markerClusterLargeXLDivFour {
	padding: 1px;
	width: 7.5em;
	height: 7.5em;
	border-radius: 7.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* animation: fadein 1.5s; */
}

.markerClusterLargeXLLabel {
	display: flex;
	justify-content: center;
	align-items: center;
}

@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.circle {
	-webkit-animation: fadein 1.5s;
	        animation: fadein 1.5s;
}

.circleTitle{
	color: #fff;
	z-index: 10000;
	padding: 10px;
}

 .number-icon-lsn{
	 /* background-image: url("https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png"); */
	 text-align:center;
	 color: white;   
	 text-shadow: 1px 1px '#fff';
	 font-size: 10px;
	 font-weight: bold;
	 background: #091c9b;
	 border-radius: 50%;
 }

 .tooltipDate{
	font-size: 8px;
	color: rgba(0, 0, 0, 0.39);
	font-family: Roboto;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	padding:2px 0px
 }

 .tooltipLocation{
	font-size: 14px;
	color: #333;
	font-family: Roboto;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	padding:2px 0px
 }

 .locationPop{
	left: -100px !important;
    top: -75px !important;
 }

 .locationPopform{
	top: -98px !important;
    left: -166px !important;
 }

 .lastupdated{
	color: #000000;
 }

 .tooltiplsn{
	background-color: #ffffff;
 }

 .tooltipName{
	font-family: Roboto;
	font-size: 8px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #000000;
 }


 /* .lsn-div-icon{
		box-sizing: border-box;
		background-image: url("../../assets/img/truck-lsn.svg");
		background-size: contain;
		background-position: 40% 40%;
		background-repeat: no-repeat;
		background-origin: content-box;
		text-shadow: 1px 1px '#fff';
		background-color: yellow;
		color: #000;
		font-weight: bold;
		text-align: center;
} */

.lsn-div-icon{
	box-sizing: border-box;
	background-color: yellow;
	color: #000;
	font-weight: bold;
	
	
} 

/* .lsn-div-icon .toolBox{
	transform: rotate(270deg) !important;
} */

/* .lsn-div-icon img{
	transform: rotate(270deg);
} */

.vehicle-number1 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #3b5a98;
  float: left; }

.statusRunning1 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: green;
  padding: 2px 0px; }

.statusStopped1 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #e6492d;
  padding: 2px 0px; }

.simIcon {
  width: auto;
  height: 12px;
  margin-left: 10px;
  padding: 0px 5px; }

.marker-cluster-custom {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid  rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-green {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-red {
  background: red;
  border: 5px solid #ededed;
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.bg-black{
  background-color: black;
  color: #ffffff;
}
.bg-red {
  background-color: red;
  color: #ffffff;
}
.bg-green {
  background-color: green;
  color: #ffffff;
}
.bg-yellow {
  background-color: yellow;
  color: #ffffff;
}
.truck-icon {
  height: 0px !important;
}
.truck-icon1 {
  height: 0px !important;
  margin-left: -20px !important;
  margin-top: -20px !important;
}
.boxgooglemap{
	width: 100%;
	height: 700px;
}
.map-label{
  position: absolute;
  margin-left: -7px;
  margin-top: 15px;
  /* transform: rotate(360deg); */
  font-weight: 900;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000;
}
.fg-red {
  color: tomato !important;
}

.fg-dark-red {
  color: red !important;
}

.fg-blue {
  color: dodgerblue !important;
}

.fg-green {
  color: mediumseagreen !important;
}

.fg-orange {
  color: orange !important;
}

.fg-grey {
  color: grey !important;
}

.fg-light-grey {
  color: #d3d3d3 !important;
}

.fg-yellow {
  color: gold !important;
}

.fg-light-green {
  color: #7CFC00 !important;
}

.fg-white {
  color: white !important;
}

.fg-black {
  color: black !important;
}

.fg-pink {
  color: deeppink !important;
}
.bg-red {
  background-color: tomato !important;
}

.bg-dark-red {
  background-color: red !important;
}

.bg-blue {
  background-color: dodgerblue !important;
}

.bg-green {
  background-color: mediumseagreen !important;
}

.bg-orange {
  background-color: orange !important;
}

.bg-grey {
  background-color: grey !important;
}

.bg-black {
  background-color: black !important;
}

.bg-yellow {
  background-color: gold !important;
}

.bg-light-green {
  background-color: #7CFC00 !important;
}

.bg-light-grey {
  background-color: #d3d3d3 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-pink {
  background-color: deeppink !important;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 2px;
  min-width: 30px;
}
.fleet-home-iw label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.markercluster-map { height: 90vh; }
.svg-red svg {
  fill: tomato;
}

.svg-dark-red svg {
  fill: red;
}

.svg-blue svg {
  fill: dodgerblue;
}

.svg-green svg {
  fill: mediumseagreen;
}

.svg-orange svg {
  fill: orange;
}

.svg-grey svg {
  fill: grey;
}

.svg-black svg {
  fill: black;
}

.svg-yellow svg {
  fill: gold;
}

.svg-light-green svg {
  fill: #7CFC00;
}

.svg-white svg {
  fill: white;
}

.svg-pink svg {
  fill: deeppink;
}
.leaflet-marker-icon-wrapper {
  margin-left: -12px;
  margin-top: -11px;
}
.leaflet-cluster-popup {
  max-height: 100px;
  overflow-y: scroll;
}
.map-label-with-text-shadow {
  position: absolute;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000;
}
.partner-marker img {
  width: 32px;
  height: 32px;
}

.Route-Activity {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  padding: 16px 24px; }

.route-card {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff; }

.divider {
  height: 1px;
  background-color: #eaedf3; }

.vehicle_list {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  padding: 12px 24px; }

.pt_10 {
  padding-top: 10px; }

.start-time {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: #9ea0a5; }

.start_date {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000; }

.detail_box {
  float: left;
  padding: 8px 14px; }

.br-l {
  border-left: 1px solid #7f7f8126; }

.br-r {
  border-right: 1px solid #7f7f81; }

.tripdetails {
  padding: 5px 0px 55px 0px; }

.tripdetails2 {
  padding: 5px 0px 5px 0px; }

.ticker-layer {
  width: 10px;
  height: 10px;
  border-radius: 8px;
  border: solid 3px #405dfe;
  background-color: #ffffff; }

.current_location {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #091c9b;
  padding: 0px 10px 0px 30px; }

.stepper {
  margin: 20px 0px;
  height: 500px;
  overflow: scroll;
  padding: 0px 20px; }

.bluebase {
  width: 16px;
  height: 16px;
  background-color: #091c9b;
  border-radius: 50%;
  color: #fff;
  line-height: 1.6;
  font-weight: 500;
  font-size: 10px; }

.litebutton {
  margin: 0px -4px; }

.whitebase {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  background-color: #ffffff; }

.dateSet {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.52);
  padding: 1px 0px; }

.hiddenHalts {
  display: none; }

.showHalts {
  display: block; }

.step-title {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
  float: left;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.step-title2 {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px; }

.top-row {
  padding-bottom: 20px; }

.delays {
  float: right;
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: right;
  color: #e53935; }

.iconAdj {
  color: #1665d8;
  font-weight: 900;
  font-size: 8px; }

.bluecircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  margin: 0px 18px -1px -7px; }

.blueboxcircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  margin: 0px 18px -1px -8px;
  background: #405dfe; }

.redcircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px red;
  margin: 0px 18px -1px -8px; }

.greyCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #999;
  margin: 0px 18px -1px -8px; }

.lineset {
  border: 1px solid #405dfe;
  height: 70px;
  width: 0px; }

.lineset4 {
  border: 1px solid #405dfe;
  border-style: dotted;
  height: 70px;
  width: 0px; }

.lineset3 {
  border: 1px solid #405dfe;
  height: 65px;
  width: 0px; }

.lineset2 {
  border: 1px solid #fff;
  height: 70px;
  width: 0px; }

.left_box {
  float: left; }

.clearing {
  clear: both; }

.whiteLetter {
  color: #fff;
  line-height: 1.0;
  font-size: 11px;
  margin: 0px 2px;
  font-weight: 500; }

.greyBackground {
  border-radius: 4px;
  background-color: #f3f3f3; }

.buttondashed {
  margin: 6px 0px;
  font-family: Roboto;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #054ada;
  height: 20px !important; }

.truncate {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.floting {
  float: left; }

.dateSet.datime {
  float: right;
  padding: 0px 10px 0px 0px; }

.currentPosition {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1665d8; }

.bluecirclebox {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  margin: 0px 18px -1px -7px;
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite; }

.blink_me {
  width: 10px;
  height: 10px;
  margin: 5px 5px;
  border-radius: 50%;
  border: solid 3px #405dfe;
  background-color: #ffffff;
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
  float: left;
  margin: 6px 8px 20px 12px; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes blinker {
  50% {
    opacity: 0; } }

.vehicleTitle {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  float: left;
  margin-right: 20px; }

.vehicleDes {
  opacity: 0.87;
  padding: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000; }

.arrowLeft {
  cursor: pointer;
  float: left;
  padding: 0px 6px 0px 0px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  position: relative;
  z-index: 100; }

.buttongroup {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 15px; }

.buttongroup button.topBt {
  margin-left: 10px; }

.pt_20 {
  padding-top: 20px; }

.grren {
  color: #1890ff; }

.marker-cluster-custom {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px; }

.marker-cluster-green {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px; }

.marker-cluster-red {
  background: red;
  border: 5px solid #ededed;
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px; }

.bg-black {
  background-color: black;
  color: #ffffff; }

.bg-red {
  background-color: red;
  color: #ffffff; }

.bg-green {
  background-color: green;
  color: #ffffff; }

.bg-yellow {
  background-color: yellow;
  color: #ffffff; }

.truck-icon {
  height: 0px !important; }

.truck-icon1 {
  height: 0px !important;
  margin-left: -20px !important;
  margin-top: -20px !important; }

.boxgooglemap {
  width: 100%;
  height: 630px; }

.map-label {
  position: absolute;
  margin-left: -7px;
  margin-top: 15px;
  /* transform: rotate(360deg); */
  font-weight: 900;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000; }

.fg-red {
  color: tomato !important; }

.fg-dark-red {
  color: red !important; }

.fg-blue {
  color: dodgerblue !important; }

.fg-green {
  color: mediumseagreen !important; }

.fg-orange {
  color: orange !important; }

.fg-grey {
  color: grey !important; }

.fg-light-grey {
  color: #d3d3d3 !important; }

.fg-yellow {
  color: gold !important; }

.fg-light-green {
  color: #7CFC00 !important; }

.fg-white {
  color: white !important; }

.fg-black {
  color: black !important; }

.fg-pink {
  color: deeppink !important; }

.bg-red {
  background-color: tomato !important; }

.bg-dark-red {
  background-color: red !important; }

.bg-blue {
  background-color: dodgerblue !important; }

.bg-green {
  background-color: mediumseagreen !important; }

.bg-orange {
  background-color: orange !important; }

.bg-grey {
  background-color: grey !important; }

.bg-black {
  background-color: black !important; }

.bg-yellow {
  background-color: gold !important; }

.bg-light-green {
  background-color: #7CFC00 !important; }

.bg-light-grey {
  background-color: #d3d3d3 !important; }

.bg-white {
  background-color: white !important; }

.bg-pink {
  background-color: deeppink !important; }

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 2px;
  min-width: 30px; }

.fleet-home-iw label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

.markercluster-map {
  height: 90vh; }

.svg-red svg {
  fill: tomato; }

.svg-dark-red svg {
  fill: red; }

.svg-blue svg {
  fill: dodgerblue; }

.svg-green svg {
  fill: mediumseagreen; }

.svg-orange svg {
  fill: orange; }

.svg-grey svg {
  fill: grey; }

.svg-black svg {
  fill: black; }

.svg-yellow svg {
  fill: gold; }

.svg-light-green svg {
  fill: #7CFC00; }

.svg-white svg {
  fill: white; }

.svg-pink svg {
  fill: deeppink; }

.leaflet-marker-icon-wrapper {
  margin-left: -12px;
  margin-top: -11px; }

.leaflet-cluster-popup {
  max-height: 100px;
  overflow-y: scroll; }

.map-label-with-text-shadow {
  position: absolute;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000; }

.helios-select {
  width: 200px;
  margin-bottom: 5px; }

.helios-filters label {
  color: #9ea0a5; }

.helios-tracking-title {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000; }

.refresh-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500; }

.helios-search {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  height: 40px; }

.helios-list-view {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  border: solid 1px #1767da;
  color: #1968dc;
  font-weight: 500;
  margin-right: 15px; }

.helios-download {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-image: linear-gradient(to top, #1665d8, #1f6fe5);
  color: #ffffff;
  font-weight: 500; }

.helios-row {
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff; }

.helios-radio-group {
  margin-top: 35px;
  float: right; }

.helios-on-radio-btn {
  margin: 0 5px;
  border-radius: 4px !important;
  font-weight: 500; }

.helios-off-radio-btn {
  border-radius: 4px !important;
  font-weight: 500; }

.helios-map-column {
  padding-left: 12px; }

.helios-list-column {
  padding: 16px;
  background-color: #ffffff;
  min-height: 600px;
  height: 600px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: -2px -1px 3px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }

.helios-vehicle-card {
  min-height: 200px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 10px; }

.helios-vehicle-num {
  font-weight: 500;
  float: left;
  color: #000;
  cursor: pointer; }

.helios-vehicle-num:hover {
  color: blue; }

.helios-track-type {
  color: #e86825;
  background-color: #ffe1be;
  float: right;
  border-radius: 3px;
  margin-right: 15px;
  padding: 1px 5px; }

.helio-trip-status {
  float: right;
  color: #000; }

.helio-trip-status-icon-on {
  float: left;
  width: 10px;
  height: 10px;
  background-color: #2d9d78;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px; }

.helio-trip-status-icon-off {
  float: left;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px; }

.helios-card-row {
  /* margin-bottom: 12px; */ }

.helios-label {
  font-size: 10px;
  color: #100e0e;
  opacity: 0.54; }

.helios-label-value {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #353535; }

.helios-right {
  text-align: end; }

.helios-card-bottom-row {
  border-top: 1px solid #eaedf3; }

.helios-vehicle-status {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #353535;
  margin-top: 15px;
  margin-bottom: 0; }

.helios-vehicle-ignition {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #353535;
  margin-top: 15px;
  margin-bottom: 0; }

.helios-call-icon {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 1px #dfdfdf;
  padding: 4px; }

.helios-search-type {
  margin: 5px; }

.location-control-btn {
  padding: 6px 14px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  border: solid 1px #b4b4b4;
  background-color: #ffffff;
  margin: 0 10px; }

.status-control-btn {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-color: #ffffff;
  margin: 0 8px;
  border: none; }

.layer-all {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #1065e1; }

.helios-trip-id {
  cursor: pointer; }

.orangecirclebox {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px orange;
  margin: 0px 18px -1px -7px; }

.lineset5 {
  border: 1px solid grey;
  border-style: dotted;
  height: 70px;
  width: 0px; }

.greencircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 3px green;
  margin: 0px 18px -1px -7px; }

.top-mar-btn {
  margin-top: 18px; }

.no-data-display {
  text-align: center;
  font-weight: 600;
  font-size: 20px; }

.sno {
  font-weight: bold;
  color: black;
  font-size: 12px; }

.helios-history-tabs div.ant-tabs-tab {
  width: 50%; }

.helios-history-tabs div.ant-tabs-nav {
  width: 100%; }

.history-tabs-container .ant-tabs-tab {
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0;
  font-weight: bold;
  border-bottom: unset;
  width: 40%;
  border: 1px sold #000; }

.history-tabs-container .ant-tabs-ink-bar {
  color: white; }

.history-tabs-container .ant-tabs-nav {
  width: 100%; }

.history-tabs-container .ant-tabs-nav .ant-tabs-tab:hover {
  color: #3b5a98; }

.history-tabs-container .ant-tabs-nav .ant-tabs-tab-active {
  color: #1665d8; }

.helios-history-container {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff; }

.helios-history-container {
  background-color: #ffffff; }

.helios-vehicle-title {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000; }

.helios-arrow-left {
  cursor: pointer;
  float: left;
  padding: 0px 6px 0px 0px;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  position: relative;
  z-index: 100;
  margin-top: 3px;
  margin-right: 5px; }

.helios-trip-started-date {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000;
  margin-left: 10px; }

.helios-route {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000; }

.helios-map-container {
  margin: 0px 30px 15px 15px;
  border-radius: 4px; }

.helios-date-picker {
  padding: 15px 30px; }

.helios-range-picker {
  width: 100%; }

.helios-his-container {
  padding: 15px 30px; }

.helios-last-trip-list {
  display: inline;
  list-style: none;
  padding: 0px; }

.helios-trip-data-list {
  list-style: none;
  padding: 0px; }

.helios-trip-data-list > li {
  display: inline; }

.helios-last-trip-list > li {
  display: inline;
  margin-right: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #05119e; }

.trip-list-label {
  opacity: 0.54;
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #100e0e; }

.helios-border-bottom {
  border-bottom: 1px solid #eaedf3; }

.helios-right {
  text-align: right; }

.last-known-location {
  background-color: #091c9b;
  color: #fff;
  font-weight: 500; }

.last-known-location-trip {
  color: #fff;
  font-weight: 500; }

.play-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500; }

.share-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500; }

.track-card {
  margin: 15px; }

.track-card-content {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #000000; }

.helios-history-trip-id {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.11;
  letter-spacing: normal;
  color: #05119e;
  text-align: center;
  margin: 0; }

.helios-info-container {
  padding: 0 10px; }

.helios-border-right {
  border-right: 1px solid #7f7f81; }

.helios-info-value {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000; }

.tp-speed {
  position: absolute;
  width: 40px !important;
  height: 85px;
  border: 1px solid #ccc;
  top: -82px !important;
  background: #fff;
  right: 15px !important; }

.primary-btn {
  background-color: rgba(59, 90, 152, 0.9);
  border: none;
  width: auto;
  vertical-align: top !important;
  height: 38px;
  vertical-align: text-top;
  padding: 10px 15px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1); }
  .primary-btn:hover, .primary-btn:active, .primary-btn:focus {
    color: white; }
  .primary-btn[disabled], .primary-btn:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, #1665d8, #1f6fe5);
    opacity: 0.5; }

.transparent-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  vertical-align: top; }
  .transparent-btn:hover, .transparent-btn:active, .transparent-btn:focus {
    background-color: transparent;
    color: #000; }

.secondary-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #fff;
  vertical-align: text-top; }
  .secondary-btn:hover, .secondary-btn:active, .secondary-btn:focus {
    background-color: transparent;
    color: #000; }

.warning-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #ed6347;
  background-color: #ed6347;
  vertical-align: text-top;
  color: #fff; }
  .warning-btn:hover, .warning-btn:active, .warning-btn:focus {
    background-color: #ed6347; }

.activity-btn {
  background-color: #f2f2f5;
  color: #000;
  padding: 5px 15px;
  vertical-align: text-top;
  border-color: #e7e7e8;
  vertical-align: text-top; }
  .activity-btn:hover, .activity-btn:active, .activity-btn:focus {
    background-color: #f2f2f5;
    color: #000; }
  .activity-btn[disabled], .activity-btn:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, #1665d8, #1f6fe5);
    opacity: 0.5; }

.close-btn {
  background-color: grey;
  color: #fff;
  padding: 8px 19px;
  border-radius: 6px;
  vertical-align: text-top; }
  .close-btn:hover, .close-btn:active, .close-btn:focus {
    color: white; }
  .close-btn[disabled], .close-btn:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, #1665d8, #1f6fe5);
    opacity: 0.5; }

.load-share-custom .ant-select-selection--single {
  vertical-align: middle;
  min-height: 38px;
  padding-top: 3px; }

.load-share-custom .ant-select-focused .ant-select-selection,
.load-share-custom .ant-select-selection:focus,
.load-share-custom .ant-select-selection:active {
  box-shadow: none; }

.load-share-custom .ant-modal-body {
  line-height: 2; }

.load-share-custom .ant-modal-footer {
  padding: 18px 20px; }

.select-dropdown .ant-select-dropdown-menu-item {
  border-bottom: 1px solid #eaeaea;
  padding: 8px 12px; }

.remit-modal .ant-select {
  width: 100%; }

.btn-group-action button {
  margin-left: 10px; }

.float-right {
  float: right; }

.clearfix {
  overflow: auto; }

.cursor-pointer {
  cursor: pointer; }

.load-share-custom .ant-modal-header {
  padding: 24px 24px;
  font-size: 20px; }

.text-blue {
  color: #1d6de2; }

.text-capitalize {
  text-transform: capitalize; }

.text-bold {
  font-weight: bold; }

.ant-table-tbody .ant-table-row:nth-child(even) {
  background-color: #f9f9f9; }

.text-capitalize {
  text-transform: capitalize; }

.inline-block {
  display: inline-block; }

button.ant-btn.filter-btn.filter-active {
  border: 1px solid #40a9ff; }

.sendemail {
  width: 140px;
  margin-right: 10px;
  padding: 18px 10px; }

.width-half {
  width: 50%; }

.status-approve-reject {
  background-color: #eaeaea;
  text-align: center;
  border-radius: 5px;
  color: #000;
  padding: 3px 3px; }

.status-approve-reject.REJECTED {
  color: #e6492d; }

.pincode-select-wrapper {
  width: 576px; }
  .pincode-select-wrapper .ant-card-head-title {
    font-size: 24px;
    font-weight: 500;
    min-height: 74px;
    padding: 19px 0; }
  .pincode-select-wrapper div:first-child label {
    padding-top: 0; }
  .pincode-select-wrapper label {
    display: block;
    padding: 16px 0 12px;
    color: #9ea0a5;
    font-size: 12px; }

.pincode-select-search {
  display: none; }
  .pincode-select-search.valid-input {
    display: block; }

.pincode-select-search-invalid {
  display: none; }
  .pincode-select-search-invalid.invalid-input {
    display: block; }

.pincode-select {
  width: 80%; }

.action-items {
  margin: 30px -24px 0;
  padding: 25px 24px 0;
  border-top: 1px solid #eaeaea; }

.CustomerDashBoard .ant-pagination, .CustomerDashBoard .ant-table-pagination {
  float: right !important; }

.customer-divider {
  height: 115vh !important; }

.CustomerDashBoard .ant-pagination, .CustomerDashBoard .ant-table-pagination {
  float: right !important; }


.zone-mapping .ant-tree-node-content-wrapper {
  width: 10vw; }

.zone-mapping .ant-select {
  width: 100% !important; }

.input-margin-lr {
  margin-left: 2px;
  margin-right: 2px; }

.mius-button {
  margin-left: 45px;
  margin-top: 50px; }

.mius-button2 {
  margin-top: 10px;
  margin-left: 45px; }

.t-button {
  margin-top: 50px; }

.t-button2 {
  margin-top: 10px; }

.ant-divider.ant-divider-horizontal.custom_divder {
  margin: 40px -43px 10px -47px !important;
  width: 108%; }

.customCard_body .ant-card-body {
  padding-bottom: 0px !important; }

.input-base-margin-lr {
  margin-left: 14px !important; }

.CustomerDashBoard .ant-pagination, .CustomerDashBoard .ant-table-pagination {
  float: right !important; }

.modal-footer {
  justify-content: normal !important;
  border: 1px solid #999; }

.close {
  cursor: pointer; }

.start-loc {
  content: ' ';
  display: inline-block;
  border-radius: 50%;
  top: 2px;
  width: 10px;
  height: 10px;
  background: red;
  border: 3px solid red;
  margin-right: 5px;
  margin-left: 5px; }

.inter-loc {
  content: ' ';
  display: inline-block;
  border-radius: 50%;
  top: 2px;
  width: 10px;
  height: 10px;
  background: #22c0e8;
  margin-right: 5px;
  margin-left: 5px; }

.end-loc {
  content: ' ';
  display: inline-block;
  border-radius: 50%;
  top: 2px;
  width: 10px;
  height: 10px;
  background: green;
  border: 3px solid green;
  margin-right: 5px;
  margin-left: 5px; }

.modal-body {
  overflow: scroll;
  max-height: 500px; }

.swal2-progresssteps li {
  display: list-item;
  position: relative; }

.swal2-progresssteps .swal2-progresscircle {
  background: #22c0e8;
  border-radius: 2em;
  color: #fff;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 2em;
  z-index: 20; }

.swal2-progresssteps span {
  top: 0px;
  left: 40px;
  color: #000;
  position: absolute;
  width: 500px;
  text-align: left;
  font-size: 14px; }

.start {
  background: red !important; }

.end {
  background: green !important; }

.swal2-progresssteps .swal2-progressline {
  background: grey;
  z-index: 10;
  margin-left: 12px;
  height: 50px;
  width: 0.3em; }

.swal2-progresssteps .swal2-progressline label {
  font-size: 12px;
  background: #fff;
  margin-top: 15px;
  margin-left: -35px !important; }

.heigth-50 {
  height: 50px; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
  background: #8A9CC2; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle,
.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
  background: #D8D8D8; }


.CustomerDashBoard .ant-pagination, .CustomerDashBoard .ant-table-pagination {
  float: right !important; }

button.user-status.ant-switch {
  background-color: #e74c3c; }

button.user-status.ant-switch.ant-switch-checked {
  background-color: #2ecc71; }

.icon-set {
  margin-left: 20px; }

.no-item {
  margin-bottom: 0 !important; }

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.pl-8 {
  padding-left: 8px !important; }

.pr-8 {
  padding-right: 8px !important; }

.role-form .ant-tree-node-content-wrapper {
  width: 10vw; }

.leaflet-container {
  height: 400px;
  width: 100%; }

#components-dropdown-demo-dropdown-button .ant-dropdown-button {
  margin: 0 8px 8px 0; }

.feList {
  border: 2px solid #3333;
  padding: 10px 22px; }

.feTitles {
  color: #999; }

.feList:hover {
  border: 2px solid blue;
  cursor: pointer; }

.fleetMap {
  width: 100%;
  height: 412px; }

.blueColor {
  border: 2px solid blue; }

input.inputBox {
  width: 40px !important; }

.onFilter {
  margin-left: -4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.onDuty {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.buttonHide {
  display: none !important; }

.imageIcon {
  color: red !important;
  margin-left: -7px !important; }

.notifyBox {
  border: 1px solid #d9d9d9;
  padding: 2px 8px;
  cursor: pointer; }

.manageChecks {
  padding: 4px 6px;
  border: 1px solid #d9d9d9; }

.feStatusValue {
  text-align: center;
  border: 1px solid #d9d9d9;
  line-height: 2.1; }

.manageSearch .ant-input {
  border-radius: 0px !important; }

.manageChecks .ant-checkbox-wrapper {
  line-height: 1.6 !important; }

.manageSearch .ant-input-group-addon .ant-input-search-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.manageSearch .ant-btn {
  margin: 0 !important; }

.headColor {
  border: 1px solid blue !important; }

.notify-btn-primary {
  background-color: rgba(59, 90, 152, 0.9) !important;
  border-color: #fff !important;
  color: #fff !important; }

.runsheetnoMap {
  text-align: center;
  padding: 60px 0px; }

.tableRow {
  border: 1px solid #999;
  padding: 0px 20px; }

.tableRow2 {
  border: 1px solid #999;
  padding: 0px 20px;
  margin-bottom: 20px; }

.tableCol {
  border-right: 1px solid #999; }

.radioArrageSet {
  margin: 8px 10px;
  font-size: 16px; }

.radioArrage2 {
  margin: 28px 10px;
  font-size: 16px; }

.titles {
  margin: 10px 0px;
  font-size: 14px;
  font-weight: 500;
  float: left; }

.title_desc {
  margin: 10px 8px;
  font-size: 14px;
  float: left; }

.title_desc_address {
  margin: 10px 8px;
  font-size: 14px;
  float: left;
  width: 300px; }

.radioArrage {
  margin: 10px 10px;
  font-size: 16px; }

.popoverImage {
  width: 250px;
  height: 350px; }

.buttoncontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.item {
  margin-top: 30px; }

.ZoneDashBoard .ant-pagination,
.ZoneDashBoard .ant-table-pagination {
  float: right !important; }

.ZoneDashBoard .ant-page-header-content {
  padding-top: 0px; }

.divider-form {
  height: 580px; }

.update-button {
  position: relative;
  bottom: 50px;
  left: 100px; }

.step-divider {
  margin: 8px 0 !important; }

.ZonerForm {
  height: 780px; }
  .ZonerForm .ant-card-head {
    margin-bottom: -10px; }
  .ZonerForm .ant-card-head-title {
    padding: 5px 0; }

.highlighter-card {
  border-radius: 5px;
  box-shadow: 2 9px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); }

.zone-card .ant-card-body {
  padding: 12px; }

.zone-card-edit {
  height: 600px; }
  .zone-card-edit .ant-form-item {
    margin-bottom: 10px; }

.ant-asset-tooltip {
  font-size: 9px !important; }
  .ant-asset-tooltip .ant-tooltip-content .ant-tooltip-inner {
    min-height: 10px !important; }

.user-invite-form .ant-row.ant-form-item {
  margin-bottom: 10px; }

.show-add {
  position: absolute;
  top: 10px;
  cursor: pointer; }

.show-minus {
  cursor: pointer; }

.boxLittle {
  padding: 8px 12px;
  float: left;
  border: 1px solid #999;
  margin: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  font-weight: 400;
  font-size: 14px; }

.boxLittle:hover {
  padding: 8px 12px;
  float: left;
  border: 1px solid #999;
  margin: 4px 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  background: rgba(59, 90, 152, 0.9);
  border-color: rgba(59, 90, 152, 0.9);
  font-weight: 400;
  font-size: 14px; }

.pad10 {
  padding: 3px; }

.boxLittleSelected {
  padding: 8px 12px;
  float: left;
  border: 1px solid #999;
  margin: 4px 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  background: rgba(59, 90, 152, 0.9);
  border-color: rgba(59, 90, 152, 0.9);
  font-weight: 400;
  font-size: 14px; }

.listPointer {
  cursor: pointer; }

.boxusertitles {
  margin: 10px 10px;
  font-size: 14px;
  font-weight: 500; }

.fleetModalFooter .ant-modal-content .ant-modal-footer {
  height: 86px !important;
  padding: 30px 25px;
  background: lightgrey !important; }

.fleetModalFooter {
  top: 10px !important; }

.fleetModalFooter .ant-modal-content .ant-modal-header {
  display: none !important; }

.fleetModalFooter .ant-modal-content .ant-modal-header {
  display: none !important; }

.userfooter {
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  padding: 10px;
  text-align: center; }

.swaping {
  cursor: pointer;
  font-size: 30px;
  padding-top: 90px; }

.cardTitle {
  border-bottom: 1px solid lightgray;
  margin-right: -24px;
  margin-left: -24px;
  padding: 0px 10px 10px 10px; }

label.selectAll.ant-checkbox-wrapper {
  float: left;
  padding: 0px 29px 0px 35px; }

.boxTitle {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px; }

.userBox {
  margin-top: 12px;
  height: 300px;
  overflow: scroll; }

.capitalize {
  text-transform: capitalize; }

.switchColor.ant-switch-checked {
  background-color: #24aa39 !important; }

.success_cust:hover {
  background-color: #95de64 !important;
  border: 1px solid #95de64 !important;
  color: #fff !important; }

.success_cust {
  background-color: #52c41a !important;
  border: 1px solid #52c41a !important;
  color: #fff !important; }

label.topCheck.ant-checkbox-wrapper {
  margin-left: 4px;
  margin-bottom: 12px; }

.svgAdj {
  margin-top: 3px;
  width: 14px;
  height: 14px; }

.has-error {
  border-color: #f5222d; }

.primaryValues {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis; }

.clientValues {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.moreValues {
  padding: 0px !important;
  color: rgba(59, 90, 152, 0.9); }

.moreValues:hover {
  padding: 0px !important;
  text-decoration: underline; }

.leftPanel {
  float: left;
  width: 50%;
  overflow: hidden; }

.rightPanel {
  float: right; }

.listHeader {
  margin-bottom: 24px; }

.legendsBox {
  background: #fff;
  z-index: 999;
  position: absolute !important;
  padding: 18px 18px;
  width: 87%;
  border: 1px solid #999;
  margin-top: 10px; }

.mainBox {
  height: 373px;
  overflow: scroll; }

.MainTitle {
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0px; }

.SubTitles {
  font-size: 14px;
  padding: 6px 0px;
  text-decoration: underline; }

.clearFix {
  clear: both; }

.boxhider {
  padding: 6px 18px 3px 14px;
  border: 1px solid #999;
  position: absolute !important;
  z-index: 1000;
  background: #fff;
  font-size: 16px;
  margin-top: 24px;
  cursor: pointer;
  transition: all 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.buttonBox {
  position: absolute !important;
  z-index: 1000;
  bottom: 12px;
  left: 20px; }

.switchColorpink.ant-switch-checked {
  background-color: #e61859 !important; }

.buttonColor {
  background-color: #e61859 !important;
  border: #e61859 !important;
  color: #fff; }

.filteredView {
  position: absolute !important;
  z-index: 1000;
  bottom: 350px;
  left: 0px; }

.filteredView2 {
  position: absolute !important;
  z-index: 1000;
  bottom: 350px;
  left: 0px; }

.listindexLevel {
  z-index: 999;
  position: absolute !important;
  background: #fff;
  max-height: 300px;
  overflow: scroll;
  width: 100%; }

.listindexLevel2 {
  z-index: 999;
  position: absolute !important;
  background: #fff;
  max-height: 300px;
  overflow: scroll;
  width: 100%; }

.leaflet-top {
  z-index: 800 !important; }

.leaflet-control-layers.leaflet-control {
  display: none !important; }

.hyperlocalModalFooter {
  top: 10px !important; }

.listindexLevel .ant-list-header {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  background: #fff !important;
  z-index: 1001 !important; }

.addCheck {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px; }

.boxNewDis {
  background-color: #f1f1f1 !important;
  pointer-events: none !important;
  opacity: 0.5 !important; }

.listData {
  width: 300px; }

.fitbox {
  margin-left: 2px !important; }

.mapControlsbox .fullscreen-icon {
  background: #fff url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png) no-repeat 0 0;
  background-size: 26px 52px; }

.mapControlsbox .leaflet-retina .fullscreen-icon {
  background: #fff url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png) no-repeat 0 0;
  background-size: 26px 26px; }

.order-list .ant-list-item {
  padding: 0px !important; }

.transfer-list-item ul.ant-transfer-list-content > div {
  margin-bottom: 15px; }
  .transfer-list-item ul.ant-transfer-list-content > div .list-phone {
    padding-left: 28px; }

.order-active .ant-tabs-tab-active.ant-tabs-tab {
  border-left: 4px solid #091c9b !important; }

.order-undel-list {
  padding: 10px;
  border-radius: 4px; }

.order-Undel-msg {
  background-color: #fbda81;
  border: 2px solid #f7b500;
  height: 60px;
  padding: 10px;
  margin-bottom: 10px; }

.ant-vendor-tooltip {
  font-size: 9px !important; }
  .ant-vendor-tooltip .ant-tooltip-content .ant-tooltip-inner {
    min-height: 10px !important; }

.vendor-view-more {
  margin-top: 10px;
  float: right;
  text-decoration: underline;
  color: #096dd9; }

.vendor-view-more:active, .vendor-view-more:hover {
  float: right;
  text-decoration: underline;
  color: #096dd9; }

.primary-btn {
  background-color: rgba(59, 90, 152, 0.9);
  border: none;
  width: auto;
  vertical-align: top !important;
  height: 38px;
  vertical-align: text-top;
  padding: 10px 15px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1); }
  .primary-btn:hover, .primary-btn:active, .primary-btn:focus {
    color: white; }
  .primary-btn[disabled], .primary-btn:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, #1665d8, #1f6fe5);
    opacity: 0.5; }

.transparent-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  vertical-align: top; }
  .transparent-btn:hover, .transparent-btn:active, .transparent-btn:focus {
    background-color: transparent;
    color: #000; }

.secondary-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #fff;
  vertical-align: text-top; }
  .secondary-btn:hover, .secondary-btn:active, .secondary-btn:focus {
    background-color: transparent;
    color: #000; }

.warning-btn {
  border: none;
  width: auto;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #ed6347;
  background-color: #ed6347;
  vertical-align: text-top;
  color: #fff; }
  .warning-btn:hover, .warning-btn:active, .warning-btn:focus {
    background-color: #ed6347; }

.activity-btn {
  background-color: #f2f2f5;
  color: #000;
  padding: 5px 15px;
  vertical-align: text-top;
  border-color: #e7e7e8;
  vertical-align: text-top; }
  .activity-btn:hover, .activity-btn:active, .activity-btn:focus {
    background-color: #f2f2f5;
    color: #000; }
  .activity-btn[disabled], .activity-btn:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, #1665d8, #1f6fe5);
    opacity: 0.5; }

.close-btn {
  background-color: grey;
  color: #fff;
  padding: 8px 19px;
  border-radius: 6px;
  vertical-align: text-top; }
  .close-btn:hover, .close-btn:active, .close-btn:focus {
    color: white; }
  .close-btn[disabled], .close-btn:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(to top, #1665d8, #1f6fe5);
    opacity: 0.5; }

.load-share-custom .ant-select-selection--single {
  vertical-align: middle;
  min-height: 38px;
  padding-top: 3px; }

.load-share-custom .ant-select-focused .ant-select-selection,
.load-share-custom .ant-select-selection:focus,
.load-share-custom .ant-select-selection:active {
  box-shadow: none; }

.load-share-custom .ant-modal-body {
  line-height: 2; }

.load-share-custom .ant-modal-footer {
  padding: 18px 20px; }

.select-dropdown .ant-select-dropdown-menu-item {
  border-bottom: 1px solid #eaeaea;
  padding: 8px 12px; }

.remit-modal .ant-select {
  width: 100%; }

.btn-group-action button {
  margin-left: 10px; }

.float-right {
  float: right; }

.clearfix {
  overflow: auto; }

.cursor-pointer {
  cursor: pointer; }

.load-share-custom .ant-modal-header {
  padding: 24px 24px;
  font-size: 20px; }

.text-blue {
  color: #1d6de2; }

.text-capitalize {
  text-transform: capitalize; }

.text-bold {
  font-weight: bold; }

.ant-table-tbody .ant-table-row:nth-child(even) {
  background-color: #f9f9f9; }

.text-capitalize {
  text-transform: capitalize; }

.inline-block {
  display: inline-block; }

button.ant-btn.filter-btn.filter-active {
  border: 1px solid #40a9ff; }

.sendemail {
  width: 140px;
  margin-right: 10px;
  padding: 18px 10px; }

.width-half {
  width: 50%; }

.status-approve-reject {
  background-color: #eaeaea;
  text-align: center;
  border-radius: 5px;
  color: #000;
  padding: 3px 3px; }

.status-approve-reject.REJECTED {
  color: #e6492d; }

.pincode-select-wrapper {
  width: 576px; }
  .pincode-select-wrapper .ant-card-head-title {
    font-size: 24px;
    font-weight: 500;
    min-height: 74px;
    padding: 19px 0; }
  .pincode-select-wrapper div:first-child label {
    padding-top: 0; }
  .pincode-select-wrapper label {
    display: block;
    padding: 16px 0 12px;
    color: #9ea0a5;
    font-size: 12px; }

.pincode-select-search {
  display: none; }
  .pincode-select-search.valid-input {
    display: block; }

.pincode-select-search-invalid {
  display: none; }
  .pincode-select-search-invalid.invalid-input {
    display: block; }

.pincode-select {
  width: 80%; }

.action-items {
  margin: 30px -24px 0;
  padding: 25px 24px 0;
  border-top: 1px solid #eaeaea; }

.waybill-link {
  color: rgba(59, 90, 152, 0.9);
  cursor: pointer; }

.filterTitles {
  font-size: 14px;
  font-weight: 500;
  margin: 8px 0px; }

.dateColor {
  color: red;
  padding: 2px 0px; }

.more:hover, .more:active, .more:visited {
  color: black; }

/* .elName {
    font-style: italic;
    font-weight: 600;
    
}

.elValue {
    margin-left: 5px;
    font-weight: bold;
    
} */
.els {

    display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10ch, 1fr));	 
    grid-gap: 0;
    width: 100%;
    margin-top: 10px;
    padding: 3px 5px 3px 20px;

}

.els .elName {
    grid-column-start: 1;
    font-weight: 400;
    overflow-wrap: break-word;

}

.els .elValue {
    grid-column-start: span 1;
    font-weight: 600;
    text-align: justify;
    overflow-wrap: break-word;
}

.els .elRemove {
    grid-column-start: span 2;    
}
pre {
    float:left;
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    float: left;
    width: 25rem;
    margin: 0 10px 0 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.title {
	text-align: center;
}


.download-btn {
    float: right;
    margin-top: 10px;
    margin-right: 20rem;
}

.button-success {
	box-shadow:inset 0px 34px 0px -15px #3dc21b;
	background-color:#44c767;
	border-radius:5px;
	border:1px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	font-weight:bold;
	padding:9px 23px;
	text-decoration:none;
	text-shadow:0px -1px 0px #2f6627;
}
.button-success:hover {
	background-color:#5cbf2a;
}
.button-success:active {
	position:relative;
	top:1px;
}
.ui-text,
.ui-text:active,
.ui-text:focus {
	text-decoration: underline;
	color: #1890ff
}

.textarea-ui {
	margin-top: 10px;
	margin-left: 10px;
	width: 700px;
	height: 100px;
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
	border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
	border-image: none;
	border-radius: 6px 6px 6px 6px;
	border-style: none solid solid none;
	border-width: medium 1px 1px medium;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
	color: #555555;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 1em;
	line-height: 1.4em;
	padding: 5px 8px;
	transition: background-color 0.2s ease 0s;
  }
  
  
  .textarea-ui:focus {
	  background: none repeat scroll 0 0 #FFFFFF;
	  outline-width: 0;
  }
@charset "UTF-8";
.elChildren {
  display: none;
  font-weight: normal;
  border-left: 1px dotted #ddd; }

.toggleObject:before {
  content: "\25B8";
  color: #333;
  line-height: 1em;
  margin-right: 5px; }

.expand > .toggleObject:before {
  content: "\25BE"; }

.Objelement.expand > .elChildren {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* grid-gap: 2em; */
  width: 40rem;
  /* text-align: center;*/ }

.toggleObject,
.stringSchema,
.numberSchema {
  cursor: pointer; }

.array-schema {
  color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-style: italic;
  margin: 0px 6px 0px 0px;
  cursor: default; }

.spin-config {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.payout-summary {
  margin: 16;
  min-height: 95vh; }
  .payout-summary .ant-modal-body,
  .payout-summary .ant-card-body {
    padding: 12px; }

.payoutDashBoard {
  background-color: #3b5a98;
  padding: 16px 30px;
  font-size: 20px;
  color: #fff;
  word-spacing: 3px;
  letter-spacing: 1px; }

.payoutHeader {
  background-color: #fafafa;
  padding: 16px 30px;
  font-size: 20px;
  color: #000;
  word-spacing: 3px;
  letter-spacing: 1px; }

.whiteCard {
  background: #f1f3f6;
  padding: 10px !important;
  margin: 10px 0px;
  border-radius: 5px; }

.whiteCard-summary {
  background: #ffff;
  padding: 10px !important;
  margin: 10px 15px;
  border-radius: 5px; }

.titlesName {
  font-weight: 500;
  font-size: 14px;
  text-align: left; }

.titlesName-right {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  position: relative;
  text-align: right; }

.titlesNumber {
  text-align: left; }

.greyCard {
  background: #f7f7f7; }

.greyCard2 {
  background: #ffffff;
  padding: 10px !important;
  margin: 10px 15px;
  overflow: scroll;
  height: 620px; }

.buttonBn {
  text-align: center; }

.divideCenter {
  margin: 5px 0 !important; }

.divideCenter2 {
  margin: 8px -5px !important; }

.redSet {
  color: #ff0202; }

.padding_to_spinner {
  margin: 0 auto;
  width: 20px;
  margin-top: 120px; }

.badge-payout .ant-badge {
  width: 100% !important; }

.download-msg-box {
  padding-top: 10px;
}

.download-box {
  text-align: center;
  width: 60%;
  margin: 28vh auto 65vh auto;
  overflow: hidden;
  
}

.download-box .h4 {
  font-size: 16px;
}

.download-box input {
  display: inline-block;
  width: 80%;
  height: 40px;
  vertical-align: top;
  margin-bottom: 10px;
}

.download-box button {
  display: inline-block;
}

.header {
  background-color: #fafafa;
  padding: 16px 30px;
  font-size: 20px;
  color: #000;
  word-spacing: 3px;
  letter-spacing: 1px; }

.rate-card-container {
  background-color: #f3f4f8;
  width: 100%;
  min-height: 100vh;
  padding: 8px; }
  .rate-card-container .card {
    background-color: #b8d0e4;
    padding: 8px;
    border-radius: 8px;
    color: #0a0808;
    margin-bottom: 10px; }
    .rate-card-container .card .card-conditions {
      background-color: #f1f6fb;
      margin-top: 8px;
      padding: 8px 16px; }
      .rate-card-container .card .card-conditions p {
        margin: 0;
        font-size: 10px; }
      .rate-card-container .card .card-conditions p:first-child {
        font-size: 12px;
        font-weight: bold; }
    .rate-card-container .card .list-view-card {
      background-color: #f1f6fb;
      margin-top: 16px;
      margin-bottom: 0px;
      padding: 8px; }
      .rate-card-container .card .list-view-card .title {
        font-size: 12px;
        font-weight: 600; }
      .rate-card-container .card .list-view-card .description {
        font-size: 10px; }
    .rate-card-container .card .combo-list-view-card {
      background-color: #f1f6fb;
      margin-top: 8px;
      padding: 8px;
      border: 1px solid #dedede; }
      .rate-card-container .card .combo-list-view-card .title {
        font-size: 14px;
        font-weight: 600; }
      .rate-card-container .card .combo-list-view-card .description {
        font-size: 10px; }
    .rate-card-container .card .card-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .rate-card-container .card img.icon {
      width: 24px;
      height: 24px; }
    .rate-card-container .card .title {
      font-size: 14px;
      font-weight: bold; }
    .rate-card-container .card .description {
      font-size: 10px; }
  .rate-card-container .timeline {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    padding: 0; }
  .rate-card-container .li {
    transition: all 200ms ease-in;
    width: 70px; }
  .rate-card-container .li:first-child {
    width: 100%; }
    .rate-card-container .li:first-child .status h4,
    .rate-card-container .li:first-child .timestamp h4 {
      width: 100px; }
  .rate-card-container .timestamp {
    margin-bottom: 5px;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 100;
    font-size: 12px; }
  .rate-card-container .status {
    padding: 0px 3px;
    display: flex;
    justify-content: flex-start;
    border-top: 2px solid #2b2b2b;
    position: relative;
    transition: all 200ms ease-in; }
  .rate-card-container .timestamp h4,
  .rate-card-container .status h4 {
    font-size: 11px;
    font-weight: normal;
    margin-top: 10px; }
  .rate-card-container .status:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 25px;
    position: absolute;
    top: -7px;
    transition: all 200ms ease-in; }
  .rate-card-container .li.complete .timestamp {
    align-items: center; }
  .rate-card-container .li.complete .status {
    border-top: 2px solid #2b2b2b;
    justify-content: center; }
  .rate-card-container .li.complete .status:before {
    background-color: #fd6a00;
    border: none;
    transition: all 200ms ease-in; }

.header {
  background-color: #fafafa;
  padding: 16px 30px;
  font-size: 20px;
  color: #000;
  word-spacing: 3px;
  letter-spacing: 1px; }

.rate-card-container {
  background-color: #f3f4f8;
  width: 100%;
  min-height: 100vh;
  padding: 8px; }
  .rate-card-container .card {
    background-color: #b8d0e4;
    padding: 8px;
    border-radius: 8px;
    color: #0a0808;
    margin-bottom: 10px; }
    .rate-card-container .card .card-conditions {
      background-color: #f1f6fb;
      margin-top: 8px;
      padding: 8px 16px; }
      .rate-card-container .card .card-conditions p {
        margin: 0;
        font-size: 10px; }
      .rate-card-container .card .card-conditions p:first-child {
        font-size: 12px;
        font-weight: bold; }
    .rate-card-container .card .list-view-card {
      background-color: #f1f6fb;
      margin-top: 16px;
      margin-bottom: 0px;
      padding: 8px; }
      .rate-card-container .card .list-view-card .title {
        font-size: 12px;
        font-weight: 600; }
      .rate-card-container .card .list-view-card .description {
        font-size: 10px; }
    .rate-card-container .card .combo-list-view-card {
      background-color: #f1f6fb;
      margin-top: 8px;
      padding: 8px;
      border: 1px solid #dedede; }
      .rate-card-container .card .combo-list-view-card .title {
        font-size: 14px;
        font-weight: 600; }
      .rate-card-container .card .combo-list-view-card .description {
        font-size: 10px; }
    .rate-card-container .card .card-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .rate-card-container .card img.icon {
      width: 24px;
      height: 24px; }
    .rate-card-container .card .title {
      font-size: 14px;
      font-weight: bold; }
    .rate-card-container .card .description {
      font-size: 10px; }
  .rate-card-container .timeline {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    padding: 0; }
  .rate-card-container .li {
    transition: all 200ms ease-in;
    width: 70px; }
  .rate-card-container .li:first-child {
    width: 100%; }
    .rate-card-container .li:first-child .status h4,
    .rate-card-container .li:first-child .timestamp h4 {
      width: 100px; }
  .rate-card-container .timestamp {
    margin-bottom: 5px;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 100;
    font-size: 12px; }
  .rate-card-container .status {
    padding: 0px 3px;
    display: flex;
    justify-content: flex-start;
    border-top: 2px solid #2b2b2b;
    position: relative;
    transition: all 200ms ease-in; }
  .rate-card-container .timestamp h4,
  .rate-card-container .status h4 {
    font-size: 11px;
    font-weight: normal;
    margin-top: 10px; }
  .rate-card-container .status:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 25px;
    position: absolute;
    top: -7px;
    transition: all 200ms ease-in; }
  .rate-card-container .li.complete .timestamp {
    align-items: center; }
  .rate-card-container .li.complete .status {
    border-top: 2px solid #2b2b2b;
    justify-content: center; }
  .rate-card-container .li.complete .status:before {
    background-color: #fd6a00;
    border: none;
    transition: all 200ms ease-in; }

/* .elName {
    font-style: italic;
    font-weight: 600;
    
}

.elValue {
    margin-left: 5px;
    font-weight: bold;
    
} */
.els {

    display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10ch, 1fr));	 
    grid-gap: 0;
    width: 100%;
    margin-top: 10px;
    padding: 3px 5px 3px 20px;

}

.els .elName {
    grid-column-start: 1;
    font-weight: 400;
    overflow-wrap: break-word;

}

.els .elValue {
    grid-column-start: span 1;
    font-weight: 600;
    text-align: justify;
    overflow-wrap: break-word;
    z-index: 999;
}

.els .elRemove {
    grid-column-start: span 2;    
}
pre {
    float:left;
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    float: left;
    width: 25rem;
    margin: 0 10px 0 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.title {
	text-align: center;
}


.download-btn {
    float: right;
    margin-top: 10px;
    margin-right: 20rem;
}

.button-success {
	box-shadow:inset 0px 34px 0px -15px #3dc21b;
	background-color:#44c767;
	border-radius:5px;
	border:1px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	font-weight:bold;
	padding:9px 23px;
	text-decoration:none;
	text-shadow:0px -1px 0px #2f6627;
}
.button-success:hover {
	background-color:#5cbf2a;
}
.button-success:active {
	position:relative;
	top:1px;
}
.ui-text,
.ui-text:active,
.ui-text:focus {
	text-decoration: underline;
	color: #1890ff
}

.textarea-ui {
	margin-top: 10px;
	margin-left: 10px;
	width: 700px;
	height: 100px;
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
	border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
	border-image: none;
	border-radius: 6px 6px 6px 6px;
	border-style: none solid solid none;
	border-width: medium 1px 1px medium;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
	color: #555555;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 1em;
	line-height: 1.4em;
	padding: 5px 8px;
	transition: background-color 0.2s ease 0s;
  }
  
  
  .textarea-ui:focus {
	  background: none repeat scroll 0 0 #FFFFFF;
	  outline-width: 0;
  }
@charset "UTF-8";
.elChildren {
  display: none;
  font-weight: normal;
  border-left: 1px dotted #ddd; }

.toggleObject:before {
  content: "\25B8";
  color: #333;
  line-height: 1em;
  margin-right: 5px; }

.expand > .toggleObject:before {
  content: "\25BE"; }

.Objelement.expand > .elChildren {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* grid-gap: 2em; */
  width: 40rem;
  /* text-align: center;*/ }

.toggleObject,
.stringSchema,
.numberSchema {
  cursor: pointer; }

.array-schema {
  color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-style: italic;
  margin: 0px 6px 0px 0px;
  cursor: default; }

.spin-config {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.helios-history-tabs div.ant-tabs-tab {
  width: 50%; }

.helios-history-tabs div.ant-tabs-nav {
  width: 100%; }

.history-tabs-container .ant-tabs-tab {
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0;
  font-weight: bold;
  border-bottom: unset;
  width: 40%;
  border: 1px sold #000; }

.history-tabs-container .ant-tabs-ink-bar {
  color: white; }

.history-tabs-container .ant-tabs-nav {
  width: 100%; }

.history-tabs-container .ant-tabs-nav .ant-tabs-tab:hover {
  color: #3b5a98; }

.history-tabs-container .ant-tabs-nav .ant-tabs-tab-active {
  color: #1665d8; }

.helios-history-container {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff; }

.helios-history-container {
  background-color: #ffffff; }

.helios-vehicle-title {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000; }

.helios-arrow-left {
  cursor: pointer;
  float: left;
  padding: 0px 6px 0px 0px;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  position: relative;
  z-index: 100;
  margin-top: 3px;
  margin-right: 5px; }

.helios-trip-started-date {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000;
  margin-left: 10px; }

.helios-route {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #000000; }

.helios-map-container {
  margin: 0px 30px 15px 15px;
  border-radius: 4px; }

.helios-date-picker {
  padding: 0px 30px; }

.helios-range-picker {
  width: 100%; }

.helios-his-container {
  padding: 5px 30px; }

.helios-last-trip-list {
  display: inline;
  list-style: none;
  padding: 0px; }

.helios-trip-data-list {
  list-style: none;
  padding: 0px; }

.helios-trip-data-list > li {
  display: inline; }

.helios-last-trip-list > li {
  display: inline;
  margin-right: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #05119e; }

.trip-list-label {
  opacity: 0.54;
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #100e0e; }

.helios-border-bottom {
  border-bottom: 1px solid #eaedf3; }

.helios-right {
  text-align: right; }

.last-known-location {
  background-color: #091c9b;
  color: #fff;
  font-weight: 500; }

.last-known-location-trip {
  color: #fff;
  font-weight: 500; }

.play-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500; }

.share-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500; }

.track-card {
  margin: 15px; }

.track-card-content {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #000000; }

.helios-history-trip-id {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.11;
  letter-spacing: normal;
  color: #05119e;
  text-align: center;
  margin: 0; }

.helios-info-container {
  padding: 0 10px; }

.helios-border-right {
  border-right: 1px solid #7f7f81; }

.helios-info-value {
  opacity: 0.87;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000; }

.tp-speed {
  position: absolute;
  width: 40px !important;
  height: 85px;
  border: 1px solid #ccc;
  top: -82px !important;
  background: #fff;
  right: 15px !important; }

.helios-divider-border-bottom {
  border-bottom: 1px solid #eaedf3; }

.marker-cluster-custom {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px; }

.marker-cluster-green {
  background: rgba(181, 226, 140, 0.6);
  border: 5px solid rgba(181, 226, 140, 0.6);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px; }

.marker-cluster-red {
  background: red;
  border: 5px solid #ededed;
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px; }

.bg-black {
  background-color: black;
  color: #ffffff; }

.bg-red {
  background-color: red;
  color: #ffffff; }

.bg-green {
  background-color: green;
  color: #ffffff; }

.bg-yellow {
  background-color: yellow;
  color: #ffffff; }

.truck-icon {
  height: 0px !important; }

.truck-icon1 {
  height: 0px !important;
  margin-left: -20px !important;
  margin-top: -20px !important; }

.boxgooglemap {
  width: 100%;
  height: 630px; }

.map-label {
  position: absolute;
  margin-left: -7px;
  margin-top: 15px;
  /* transform: rotate(360deg); */
  font-weight: 900;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000; }

.fg-red {
  color: tomato !important; }

.fg-dark-red {
  color: red !important; }

.fg-blue {
  color: dodgerblue !important; }

.fg-green {
  color: mediumseagreen !important; }

.fg-orange {
  color: orange !important; }

.fg-grey {
  color: grey !important; }

.fg-light-grey {
  color: #d3d3d3 !important; }

.fg-yellow {
  color: gold !important; }

.fg-light-green {
  color: #7cfc00 !important; }

.fg-white {
  color: white !important; }

.fg-black {
  color: black !important; }

.fg-pink {
  color: deeppink !important; }

.bg-red {
  background-color: tomato !important; }

.bg-dark-red {
  background-color: red !important; }

.bg-blue {
  background-color: dodgerblue !important; }

.bg-green {
  background-color: mediumseagreen !important; }

.bg-orange {
  background-color: orange !important; }

.bg-grey {
  background-color: grey !important; }

.bg-black {
  background-color: black !important; }

.bg-yellow {
  background-color: gold !important; }

.bg-light-green {
  background-color: #7cfc00 !important; }

.bg-light-grey {
  background-color: #d3d3d3 !important; }

.bg-white {
  background-color: white !important; }

.bg-pink {
  background-color: deeppink !important; }

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 2px;
  min-width: 30px; }

.fleet-home-iw label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

.markercluster-map {
  height: 90vh; }

.svg-red svg {
  fill: tomato; }

.svg-dark-red svg {
  fill: red; }

.svg-blue svg {
  fill: dodgerblue; }

.svg-green svg {
  fill: mediumseagreen; }

.svg-orange svg {
  fill: orange; }

.svg-grey svg {
  fill: grey; }

.svg-black svg {
  fill: black; }

.svg-yellow svg {
  fill: gold; }

.svg-light-green svg {
  fill: #7cfc00; }

.svg-white svg {
  fill: white; }

.svg-pink svg {
  fill: deeppink; }

.leaflet-marker-icon-wrapper {
  margin-left: -12px;
  margin-top: -11px; }

.leaflet-cluster-popup {
  max-height: 100px;
  overflow-y: scroll; }

.map-label-with-text-shadow {
  position: absolute;
  color: white;
  text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000, 1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000, -1px 1px 5px #000; }

.helios-select {
  width: 200px;
  margin-bottom: 5px; }

.helios-filters label {
  color: #9ea0a5; }

.helios-tracking-title {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000; }

.refresh-helios {
  border-radius: 4px;
  border: solid 1px #dfe2e5;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  font-weight: 500; }

.helios-track {
  background-color: #1665d8 !important; }

.helios-track {
  background-image: linear-gradient(to top, #1665d8, #1f6fe5) !important;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
  border-radius: 4px !important;
  color: #ffffff !important; }

.helios-track:visited,
.helios-track:focus,
.helios-track:hover {
  background-image: linear-gradient(to top, #1665d8, #1f6fe5) !important;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1) !important;
  border-radius: 4px !important;
  color: #ffffff !important; }

.helios-list-view {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  border: solid 1px #1767da;
  color: #1968dc;
  font-weight: 500;
  margin-right: 15px; }

.helios-download {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-image: linear-gradient(to top, #1665d8, #1f6fe5);
  color: #ffffff;
  font-weight: 500; }

.helios-row {
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(110, 112, 115, 0.2);
  background-color: #ffffff; }

.helios-radio-group {
  margin-top: 35px;
  float: right; }

.helios-on-radio-btn {
  margin: 0 5px;
  border-radius: 4px !important;
  font-weight: 500; }

.helios-off-radio-btn {
  border-radius: 4px !important;
  font-weight: 500; }

.helios-map-column {
  padding-left: 12px; }

.helios-list-column {
  padding: 16px;
  background-color: #ffffff;
  min-height: 600px;
  height: 600px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: -2px -1px 3px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; }

.helios-vehicle-card {
  min-height: 200px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 10px; }

.helios-vehicle-num {
  font-weight: 500;
  float: left;
  color: #000;
  cursor: pointer;
  color: blue;
  text-decoration: underline; }

.helios-vehicle-num:hover {
  color: blue; }

.helios-track-type {
  color: #e86825;
  background-color: #ffe1be;
  float: right;
  border-radius: 3px;
  margin-right: 15px;
  padding: 1px 5px; }

.helio-trip-status {
  float: right;
  color: #000; }

.helio-trip-status-icon-on {
  float: left;
  width: 10px;
  height: 10px;
  background-color: #2d9d78;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px; }

.helio-trip-status-icon-off {
  float: left;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px; }

.helios-card-row {
  /* margin-bottom: 12px; */ }

.helios-label {
  font-size: 10px;
  color: #100e0e;
  opacity: 0.54; }

.helios-label-value {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #353535; }

.helios-right {
  text-align: end; }

.helios-card-bottom-row {
  border-top: 1px solid #eaedf3; }

.helios-vehicle-status {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #353535;
  margin-top: 15px;
  margin-bottom: 0; }

.helios-vehicle-ignition {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #353535;
  margin-top: 15px;
  margin-bottom: 0; }

.helios-call-icon {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 1px #dfdfdf;
  padding: 4px; }

.helios-search-type {
  margin: 5px; }

.location-control-btn {
  padding: 6px 14px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  border: solid 1px #b4b4b4;
  background-color: #ffffff;
  margin: 0 10px; }

.status-control-btn {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
  background-color: #ffffff;
  margin: 0 8px;
  border: none; }

.layer-all {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #1065e1; }

.helios-trip-id {
  cursor: pointer;
  color: blue;
  text-decoration: underline; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; }

.outermost-div-otp {
  display: flex; }

.outer-left-div-otp {
  width: 720px;
  height: 760px;
  padding: 220px 160px 258px;
  background-color: #fff; }

.logo-valid-otp {
  width: 720px;
  height: 760px;
  padding: 170px 160px 258px;
  /* background-color: red; */ }

.logo-otp {
  width: 278px;
  height: 80px;
  flex-grow: 0;
  margin: 0 122px 10px 0;
  object-fit: contain; }

#title-opt {
  width: 120px;
  height: 30px;
  flex-grow: 0;
  margin: 30px 281px 0px 0;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: left;
  color: #051c57; }

.login-tabpane-otp {
  width: 400px;
  height: 36px;
  margin: 32px 0 10px;
  padding: 8px 224px 8px 12px;
  border-radius: 4px;
  border: solid 1px #e6e7e7;
  background-color: #fff; }

.login-tabpane-otp::-webkit-input-placeholder {
  /* design - width: 164px; */
  width: 170px;
  height: 20px;
  opacity: 0.4;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #273142; }

.login-tabpane-otp::placeholder {
  /* design - width: 164px; */
  width: 170px;
  height: 20px;
  opacity: 0.4;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #273142; }

.login-tabpane-otp-received {
  width: 400px;
  height: 36px;
  margin: 0px 0 10px;
  padding: 8px 224px 8px 12px;
  border-radius: 4px;
  border: solid 1px #e6e7e7;
  background-color: #fff; }

.login-tabpane-otp-received::-webkit-input-placeholder {
  /* design - width: 164px; */
  width: 170px;
  height: 20px;
  opacity: 0.4;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #273142; }

.login-tabpane-otp-received::placeholder {
  /* design - width: 164px; */
  width: 170px;
  height: 20px;
  opacity: 0.4;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #273142; }

.confirm-otp {
  width: 45px;
  height: 22px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #fff; }

.outer-right-div {
  width: 50%;
  background-color: #3B56EA;
  background-image: url(/static/media/loginimg.81427824.svg);
  background-repeat: no-repeat;
  height: 100vh; }

@media (min-width: 640px) {
  body {
    font-size: 1rem; } }

@media (min-width: 960px) {
  body {
    font-size: 1.2rem; } }

@media (min-width: 1024px) {
  body {
    font-size: 1.2rem; } }

@media (min-width: 1024px) and (min-width: 1100px) {
  body {
    font-size: 1.5rem; } }

.pincode-client-onboarding {
  width: 29vw;
  height: 36px;
  padding: 0px 0px 0px 12px;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  display: 'inline-block'; }

.pincode-client-onboarding:focus {
  outline: solid 1px #51aefb; }

.pincode-client-onboarding::-webkit-input-placeholder {
  color: #bfbfbf; }

.pincode-client-onboarding::placeholder {
  color: #bfbfbf; }

.outer-container-client-dashboard {
  float: right; }

.search-client-dashboard {
  width: 20vw; }

.reload-button-client-dashboard {
  margin-left: 10px;
  width: 3vw; }

.outer-div-fmlm {
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%; }

.switchColor.ant-switch-checked {
  background-color: #24aa39 !important; }

.inputModal-number-format {
  width: 30%;
  border-radius: 4px;
  border: solid 1px #e6e7e7;
  background: #fff; }

.upper-inputModal-fmlm {
  background-color: #dee6ec;
  border: 1.5px solid #b9c4cf;
  height: 14vh;
  padding: 20px; }

.lower-inputModal-fmlm {
  background-color: #f8f9fa;
  border: 1.5px solid #c2cfd9;
  height: 14vh;
  margin-top: 20px;
  padding: 20px; }

.input-div-inputModal-fmlm {
  display: flex; }

.input-inputModal-fmlm {
  width: 10wv; }

.submit-inputModal-fmlm {
  margin-top: 5%;
  justify-content: center;
  background-color: #2d5de5;
  color: white; }

.lower-div-fmlm {
  display: flex;
  justify-content: center;
  margin-top: 20px; }

.count-div-staffStatus {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaedf2;
  padding-bottom: 20px; }

.total-count-div {
  background: #f6f7f9;
  border: 1px solid #ebedf8;
  width: 348px;
  border-radius: 4px;
  height: 88px;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06); }

.total-count-div:hover {
  background: #e8effd;
  cursor: pointer; }

.total-count-div-selected {
  color: white;
  background-color: #42409b;
  border: 1px solid rgba(77, 74, 181, 0.24);
  width: 348px;
  border-radius: 4px;
  height: 88px;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box; }

.total-count-value {
  font-size: 18px;
  font-weight: 800; }

.input-group-staffStatus {
  width: 30%;
  margin: 10px 0 10px 0;
  padding-top: 10px; }

.input-staffStatus .ant-input {
  border: 1px solid #d4d4ed;
  width: 100%;
  border-radius: 8px; }

.input-button-staffStatus
.ant-input-search-enter-button
+ .ant-input-group-addon
.ant-input-search-button,
.ant-input-search-enter-button
input
+ .ant-input-group-addon
.ant-input-search-button {
  border-radius: 0 8px 8px 0; }

.input-filter-grp-staffStatus {
  display: flex;
  margin: 8px 0 8px 0; }

.filter-outer-div-staffStatus {
  display: flex;
  margin: 22px 0px 20px 0px; }

.filter-inner-div-staffStatus {
  display: flex; }

.filter-btn-div-staffStatus {
  margin-left: 50px;
  font-size: 14px;
  background: #f6f7fb;
  border-radius: 24px;
  height: 5vh; }

.filter-btn-staffStatus {
  color: #525050;
  background: #ffffff;
  border: 1px solid rgba(77, 74, 181, 0.24);
  border-radius: 16px;
  margin: 3px 5px 3px 3px; }

.filter-btn-staffStatus-selected {
  color: white;
  background-color: #42409b;
  border: 1px solid rgba(77, 74, 181, 0.24);
  border-radius: 16px;
  margin: 3px 5px 3px 3px; }

.cancelBtn-staffStatus-selected {
  color: white;
  margin-left: 5px;
  background: none;
  border: none;
  height: 20px;
  width: 4px;
  display: inline; }

.cancelBtn-staffStatus-selected:hover {
  background: none;
  color: red;
  cursor: pointer; }

.display-none-staffStatus-selected {
  display: none; }

.table-staffStatus {
  background: transparent;
  border: none; }

.FEStatusDashboard .ant-table-thead > tr > th {
  background-color: transparent;
  border: none;
  padding-bottom: 0px;
  color: #808080;
  background: #f6f7fb;
  padding: 0 0 0 20px; }

.FEStatusDashboard .ant-table-bordered .ant-table-body > table {
  border: 1px solid #ebedf8;
  border-radius: 4px; }

.FEStatusDashboard .ant-table-bordered .ant-table-tbody > tr > td {
  border: none; }

.FEStatusDashboard .select-container.ant-form-item-control-wrapper {
  width: 50%; }

.FEStatusDashboard .anticon-search {
  font-size: 20px;
  padding-top: 5px; }

.modal-staffStatus .ant-modal-content {
  border-radius: 24px;
  width: 468px;
  height: 317px;
  border-radius: 24px; }

.modal-staffStatus .ant-modal-header {
  border-radius: 24px 24px 0 0;
  border: none;
  font-weight: 700;
  font-size: 24px; }

.modal-staffStatus .ant-modal-footer {
  margin-top: 100px; }

.modal-staffStatus .ant-btn {
  height: 36px;
  width: 130px;
  border-radius: 8px;
  margin-top: 10px; }

.option-staffStatus
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e8effd; }

.option-staffStatus .ant-select-dropdown-menu-item {
  line-height: 40px;
  border-bottom: rgba(255, 255, 255, 0.2); }

.deliveryHistory-div-staffStatus {
  display: flex;
  border: 1px solid black;
  border-radius: 4px; }

