.leaflet-container {
    height: 400px;
    width: 100%;
  }

  #components-dropdown-demo-dropdown-button .ant-dropdown-button {
    margin: 0 8px 8px 0;
  }


.feList {
    border: 2px solid #3333;
    padding: 10px 22px;
}

.feTitles {
  color: #999;
}

.feList:hover {
  border: 2px solid blue;
  cursor: pointer;
}

.fleetMap{
  width: 100%;
    height: 412px;
}

.blueColor{
  border: 2px solid blue;
}

input.inputBox {
  width: 40px !important;
}

.onFilter {
  margin-left: -4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.onDuty {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.buttonHide{
  display:none !important;
}

.imageIcon {
  color: red !important;
  margin-left: -7px !important;
}

.notifyBox {
  border: 1px solid #d9d9d9;
  padding: 2px 8px;
  cursor: pointer;
}

.manageChecks{
  padding: 4px 6px;
  border: 1px solid #d9d9d9;
}

.feStatusValue {
  text-align: center;
  border: 1px solid #d9d9d9;
  line-height: 2.1;
}

.manageSearch .ant-input{
  border-radius: 0px !important;
}

.manageChecks .ant-checkbox-wrapper{
  line-height: 1.6 !important;
}

.manageSearch .ant-input-group-addon .ant-input-search-button{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.manageSearch .ant-btn {
  margin: 0 !important;
}

.headColor{
  border: 1px solid blue !important;
}

.notify-btn-primary {
  background-color: rgba(59, 90, 152, 0.9) !important;
  border-color: #fff !important;
  color: #fff !important;
}

.runsheetnoMap {
  text-align: center;
  padding: 60px 0px;
}

.tableRow {
  border: 1px solid #999;
  padding: 0px 20px;
}

.tableRow2 {
  border: 1px solid #999;
  padding: 0px 20px;
  margin-bottom: 20px;
}

.tableCol {
  border-right: 1px solid #999;
}

.radioArrageSet {
  margin: 8px 10px;
  font-size: 16px;
}

.radioArrage2 {
  margin: 28px 10px;
  font-size: 16px;
}