.titles {
    margin: 10px 0px;
    font-size: 14px;
    font-weight: 500;
    float: left;
}

.title_desc {
    margin: 10px 8px;
    font-size: 14px;
    float: left;
}

.title_desc_address{
    margin: 10px 8px;
    font-size: 14px;
    float: left;
    width: 300px;
}

.radioArrage {
    margin: 10px 10px;
    font-size: 16px;
}

.popoverImage{
    width: 250px;
    height: 350px
}

.buttoncontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }
  .item {
    margin-top:30px;
  }